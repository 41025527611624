import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AfaqyControl } from '../../../common/afaqy-control';
import { AuthService } from '../../../core/services/auth.service';

import { afaqyLocal } from './afaqy-local';
import { Calendar } from 'primeng/calendar';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  exportAs: 'afaqyDateCalendar',
  selector: 'afaqy-date-calendar',
  templateUrl: './afaqy-date-calendar.component.html',
  styleUrls: ['./afaqy-date-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyDateCalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyDateCalendarComponent),
      multi: true,
    },
  ],
})
export class AfaqyDateCalendarComponent
  extends AfaqyControl
  implements OnInit, OnDestroy
{
  @ViewChild('calendarTo') calendarTo: Calendar;

  cid = 'date_intervals';
  form: UntypedFormGroup;
  showInterval = false;
  disableTo = false;
  live = true;
  routerPrefix: string;
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() predefinedDateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() editDate = null;
  @Input() hideButtons;
  @Input() maxIntervalCount = 3;
  @Input() maxIntervalUnit: 'months' | 'weeks' | 'days' = 'months';
  period: string = '';
  @Input() intervals = [
    { val: 'interval', txt: 'date_intervals.interval' },
    { val: 'from_until_today', txt: 'date_intervals.from_until_today' },
    { val: 'for_previous', txt: 'date_intervals.for_previous' },
  ];
  @Input() customIntervalUnits;
  calendarLocale = {
    en: {
      firstDayOfWeek: 0,
      dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk',
    },
    ar: {
      firstDayOfWeek: 0,
      dayNames: [
        'الأحد',
        'الإثنين',
        'الثلاثاء',
        'الأربعاء',
        'الخميس',
        'الجمعة',
        'السبت',
      ],
      dayNamesShort: [
        'أحد',
        'إثنين',
        'ثلاثاء',
        'أربعاء',
        'خميس',
        'جمعة',
        'سبت',
      ],
      dayNamesMin: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
      monthNames: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
      monthNamesShort: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
      today: 'اليوم',
      clear: 'مسح',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk',
    },
  };

  intervalUnits = [
    { val: 'minutes', txt: 'date_intervals.minutes' },
    { val: 'hours', txt: 'date_intervals.hours' },
    { val: 'days', txt: 'date_intervals.days' },
    { val: 'weeks', txt: 'date_intervals.weeks' },
    { val: 'months', txt: 'date_intervals.months' },
  ];
  currentLang = 'en';
  userTime;
  maxDate: Date = null;
  minDate: Date = null;
  minToDate: Date = null;
  oldToDateValue: Date;
  maxPreviousValue: any = {
    minutes: 129600,
    hours: 2160,
    days: 90,
    weeks: 12,
    months: 3,
  };
  timeDiffToggle: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private ElementRef: ElementRef,
    private router: Router,
    private translate: TranslateService
  ) {
    super();
    const days = {
      saturday: 6,
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
    };
    const number = days[this.authService.user.user_settings.start_weekday];
    afaqyLocal.week = {
      dow: number,
      doy: number, //7 + dow - janX, where janX is the first day of January that must belong to the first week of the year.
    };
    moment.updateLocale('en', afaqyLocal);
    this.setUserTimeAndMaxDate();
    this.currentLang = this.translate.currentLang;
    this.initForm();
    // subtract 1 year from the currengt date to get minFrom date
    this.minDate = moment(this.maxDate).subtract(1, 'year').toDate();
    Object.keys(this.maxPreviousValue).forEach((unit) => {
      this.calculateMaxIntervalForEachUnit(unit);
    });
    // check if route contains second-by-second
    if (this.router.url.includes('second-by-second')) {
      this.intervalUnits = this.intervalUnits.filter(
        (unit) => unit.val !== 'months'
      );
    }
  }

  ngOnDestroy() {
    this.live = false;
  }

  ngOnInit() {
    // subscribe to language change to update the calendar
    this.translate.onLangChange.subscribe((lang) => {
      this.currentLang = lang.lang;
    });
    this.intervalUnits = this.customIntervalUnits
      ? this.customIntervalUnits
      : this.intervalUnits;

    this.toggleInterval();
    this.created.next({ formGroup: this.form });
    // needed if the value arrived before init
    setTimeout(() => {
      if (this.editDate) {
        this.form.get('from').patchValue(new Date(this.editDate.from));
        this.form.get('to').patchValue(new Date(this.editDate.to));
        this.oldToDateValue = new Date(this.editDate.to);
      }
    }, 50);
    for (let field of [
      'interval',
      'interval_count',
      'interval_unit',
      'including_current',
    ]) {
      this.form
        .get(field)
        ?.valueChanges.pipe(distinctUntilChanged())
        .subscribe(() => {
          this.setFromTO();
        });
    }
    this.form.valueChanges.subscribe({
      next: () => {
        this.pushValue();
      },
    });
    this.pushValue();
    this.routerPrefix = this.router.url.split('/')[1].split('?')[0];
  }

  ngOnChanges(change) {
    if (change.editDate && change.editDate.currentValue) {
      // needed if we pass the formInit creation
      // if form not created it would cause error
      if (this.form) {
        this.form
          .get('from')
          .patchValue(new Date(change.editDate.currentValue.from));
        this.form
          .get('to')
          .patchValue(new Date(change.editDate.currentValue.to));
      }
    }
    if (
      change.maxIntervalUnit?.currentValue ||
      change.maxIntervalCount?.currentValue
    ) {
      Object.keys(this.maxPreviousValue).forEach((unit) => {
        this.calculateMaxIntervalForEachUnit(unit);
      });
    }
  }

  initForm() {
    const toDate = this.userTime?.toDate();
    this.form = this.fb.group({
      interval: ['interval', []],
      from: [
        moment().startOf('day').toDate(),
        this.timeDiffValidator.bind(this),
      ],
      to: [toDate, this.timeDiffValidator.bind(this)],
      interval_count: [1, []],
      interval_unit: ['minutes', []],
      including_current: [false, []],
    });
  }
  getMinIntervals(unit: string) {
    const intervals = {
      minutes: 1, // smallest unit
      hours: 60, // 60 minutes in an hour
      days: 1440, // 24 hours in a day, so 1440 minutes
      weeks: 10080, // 7 days in a week, so 10080 minutes
      months: 43200, // assuming 30 days in a month, so 43200 minutes
    };
    return intervals[unit];
  }

  calculateMaxIntervalForEachUnit(unit: string) {
    if (this.maxIntervalUnit === 'months') {
      // get the count of the days if the maxIntervalUnit is months
      let maxDaysCount = 0;
      // get the count of days for each month of the maxIntervalCount
      for (let i = 1; i <= this.maxIntervalCount; i++) {
        const daysInMonth = moment(this.maxDate)
          .subtract(i, 'months')
          .daysInMonth();
        maxDaysCount += daysInMonth;
      }
      // Now use maxDaysCount to update maxPreviousValue for the target unit
      const targetUnitInMinutes = this.getMinIntervals(unit); // get the target unit's minutes

      // Calculate the maxPreviousValue based on the maxDaysCount and the target unit
      this.maxPreviousValue[unit] = Math.floor(
        (maxDaysCount * 24 * 60) / targetUnitInMinutes
      );
    } else {
      // If maxIntervalUnit is not 'months', use the normal conversion factor
      const currentUnitInMinutes = this.getMinIntervals(this.maxIntervalUnit); // the base unit
      const targetUnitInMinutes = this.getMinIntervals(unit); // the target unit (weeks, days, etc.)

      // Calculate the conversion factor between the units
      const conversionFactor = currentUnitInMinutes / targetUnitInMinutes;

      // Update the maxPreviousValue based on the conversion
      this.maxPreviousValue[unit] = Math.floor(
        this.maxIntervalCount * conversionFactor
      );
    }
    // ensure only integer values
    this.maxPreviousValue[unit] = Math.floor(this.maxPreviousValue[unit]);
  }

  setFromTO() {
    const interval = this.form.controls['interval'].value;
    if (interval == 'interval') return;

    let fromDate: any;
    let toDate: any;
    const interval_count = this.form.controls['interval_count'].value;
    const interval_unit = this.form.controls['interval_unit'].value;

    if (interval == 'for_previous') {
      fromDate = this.calculateFromDate(interval_unit, interval_count);
    } else fromDate = moment(this.form.get('from').value);

    const includingCurrent = this.form.controls['including_current'].value;
    toDate = includingCurrent
      ? moment()
      : moment(fromDate).add(interval_count, interval_unit);
    // Convert `fromDate` and `toDate` to JavaScript Date objects
    const fromDateObj = fromDate.toDate();
    const toDateObj = toDate.toDate();

    this.updateFormDates(fromDateObj, toDateObj);
  }

  onIntervalCountChanged() {
    const intervalUnit = this.form.get('interval_unit').value;
    const intervalCount = this.form.get('interval_count').value;
    if (intervalCount > this.maxPreviousValue[intervalUnit]) {
      this.form
        .get('interval_count')
        .patchValue(this.maxPreviousValue[intervalUnit]);
    }
  }

  updateFormDates(fromDateObj: Date, toDateObj: Date) {
    if (
      this.editDate &&
      this.editDate.interval === this.form.controls['interval'].value
    ) {
      // If in edit mode, use the existing `from` and `to` dates
      this.form.get('from').patchValue(new Date(this.editDate.from));
      this.form.get('to').patchValue(new Date(this.editDate.to));
      this.oldToDateValue = new Date(this.editDate.to);
    } else {
      // Otherwise, set the calculated `fromDateObj` and `toDateObj`
      this.form.controls['from'].setValue(fromDateObj);
      this.form.controls['to'].setValue(toDateObj);
      this.oldToDateValue = toDateObj;
    }
  }

  calculateFromDate(interval_unit, interval_count) {
    return moment()
      .subtract(interval_count, interval_unit)
      .startOf(interval_unit);
  }

  toggleInterval(update = false) {
    const intervalValue = this.form.controls['interval'].value;
    this.showInterval =
      intervalValue == 'interval' || intervalValue == 'from_until_today';
    this.disableTo = intervalValue == 'from_until_today';
    if (this.calendarTo) this.calendarTo.disabled = this.disableTo;
    if (this.showInterval && update) {
      const currentMoment = this.userTime;
      const fromDate = moment(this.form.get('from').value)
        .startOf('day')
        .toDate();
      this.form.controls['from'].setValue(fromDate); // From will be calculated to start of the day
      this.form.controls['to'].setValue(currentMoment.toDate()); // To will be calculated to current moment
      this.oldToDateValue = currentMoment.toDate();
    }
  }

  updateDate(period_unit) {
    if (period_unit == 'today') {
      this.form.controls['interval'].setValue('interval');
      let today = moment().startOf('day').toDate();
      // Today at 00:00:00
      this.form.controls['from'].setValue(today);
      const currentMoment = this.userTime.toDate();
      this.form.controls['to'].setValue(currentMoment); // To will be calculated to current moment
      this.oldToDateValue = currentMoment;
    } else if (period_unit == 'yesterday') {
      this.form.controls['interval'].setValue('for_previous');
      this.form.controls['interval_unit'].setValue('days');
      this.form.controls['interval_count'].setValue(1);
    } else if (period_unit != 'today') {
      this.form.controls['interval'].setValue('for_previous');
      this.form.controls['interval_unit'].setValue(period_unit);
      this.form.controls['interval_count'].setValue(1);
    }
    this.toggleInterval();
    this.predefinedDateChanged.next();
    this.pushValue();
    this.period = period_unit;
  }

  pushValue() {
    if (!this.writing) {
      this.propagateChange(this.form.value);
    }
  }

  writeValue(value: any) {
    if (value) {
      this.writing = true;
      // let value.from and to be Date objects
      value.from = new Date(value.from);
      value.to = new Date(value.to);

      this.form.reset(value);
      if (moment(value.to) > moment(this.maxDate)) {
        this.form.get('to').patchValue(this.maxDate);
        this.oldToDateValue = this.maxDate;
      }
      this.toggleInterval();
      this.setFromTO();
      this.writing = false;
    }
  }

  reWriteValue() {
    this.pushValue();
  }

  onToDateValueChanged(e: any) {
    this.setUserTimeAndMaxDate();
    // check if toDate is a valid date
    if (!moment(e).isValid()) {
      this.form.get('to').patchValue(this.maxDate);
    }
    if (moment(e) > moment(this.maxDate)) {
      this.form.get('to').patchValue(this.maxDate);
    }
    // is the user only changed the time of the toDate and the date is the same as the oldvalue of the toDate
    // then do nothing
    if (moment(e).isSame(this.oldToDateValue, 'day')) return;
    let toDate = moment(e).toDate();
    // if the user enters that is not today make the time to be 23:59:59
    if (!moment(e).isSame(this.userTime, 'day')) {
      toDate = moment(e).endOf('day').toDate();
      this.form.get('to').patchValue(toDate);
    }
    this.oldToDateValue = toDate;
  }

  onFromDateValueChanged(e: any) {
    this.setUserTimeAndMaxDate();
    // // check if fromDate is greater than toDate
    if (moment(e) > moment(this.form.get('to').value)) {
      //  change toDate to be the greater than fromDate by 10 minutes
      const fromDate = moment(this.form.get('from').value);
      const toDate = moment(fromDate).endOf('day').toDate();
      this.form.get('to').patchValue(toDate);
      this.oldToDateValue = toDate;
    }
    // this.timeDiffValidator();
  }

  /*
    set user time to be the current time in the user timezone
    set maxDate
  */
  setUserTimeAndMaxDate() {
    // Initialize userTime and maxDate based on the user's timezone.
    this.userTime = moment.tz(moment.now(), this.authService.user.timezone);
    this.maxDate = this.userTime.toDate();
  }

  validateFn = (c: UntypedFormControl) => {
    return this.form.valid ? null : { date: 'invalid' };
  };

  timeDiffValidator() {
    let maxAllowedTimeDiffInDays = this.maxPreviousValue.days; // Max allowed difference in days
    const fromDate = moment(this.form?.get('from')?.value)?.startOf('day'); // Start at the beginning of the day (00:00:00)
    const toDate = moment(this.form?.get('to')?.value)?.startOf('day'); // Start at the beginning of the day (00:00:00)

    // Calculate the difference in full days
    const timeDiffInDays = toDate.diff(fromDate, 'days');

    // Toggle error if time difference exceeds the allowed days
    const hasExceededTimeDiff = timeDiffInDays > maxAllowedTimeDiffInDays;
    this.timeDiffToggle = hasExceededTimeDiff;
    if (!hasExceededTimeDiff) {
      this.form?.get('to')?.setErrors(null);
      this.form?.get('from')?.setErrors(null);
    }
    return hasExceededTimeDiff ? { timeDiff: true } : null;
  }
}
