import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import {
  MatNativeDateModule,
  MatDateFormats,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export function createDynamicDateFormat(format: string): MatDateFormats {
  return {
    parse: {
      dateInput: format,
    },
    display: {
      dateInput: format,
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
}
@Component({
  selector: 'avl-workspace-input-date',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
  ],
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],

  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: () => createDynamicDateFormat('YYYY/MM/DD'),
    },

    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputDateComponent),
    },
  ],
})
export class InputDateComponent implements OnInit, ControlValueAccessor {
  @Input() public isRequired = false;
  @Input() public isDisabled = false;
  @Input() public minDate: any = null;
  @Input() public apiDateFormat: any = 'MM-DD-YYYY';
  @Input() public value: any;

  @Output() dateChanged: EventEmitter<any> = new EventEmitter();
  private formattedDate: any;
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  public ngOnInit() {
    this.minDate = this.formatDate(this.minDate);
  }

  public writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.value = value;
      this.formattedDate = this.formatDate(this.value);
    } else {
      this.value = null;
      this.formattedDate = null;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onValueChange(value: any): void {
    if (value) {
      this.value = value;
      this.formattedDate = this.formatDate(this.value);
      // console.log(this.value);
      // console.log(this.formattedDate);
      this.dateChanged.emit(this.formattedDate);
      this.onChange(this.formattedDate);
      this.onTouched();
    }
  }

  private formatDate(dateInput: string | Date): any {
    const date = new Date(dateInput);
    // Check if the date is valid
    if (!dateInput || isNaN(date.getTime())) {
      return dateInput;
    }
    return moment(date).format(this.apiDateFormat);
  }
  // setDisabledState?(isDisabled: boolean) {
  //   this.isDisabled = isDisabled;
  // }
}
