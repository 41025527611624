import { AfaqyObject, AppModel } from '../../../common/classes';
export class UnitSharedGroup extends AppModel {
  private _name: string;
  private _unitsList: string[] = [];
  private _unitsListCount: any;
  private _crat: any;
  private _shared_data: SharedGroupData = new SharedGroupData();

  protected copyFields = [
    'id',
    'name',
    'crat',
    'user_id',
    'unitsList',
    'users_list',
    'shared_data',
  ];

  protected createFields = [
    'id',
    'name',
    'crat',
    'user_id',
    'unitsList',
    'users_list',
    'shared_data',
  ];
  protected updateFields = [
    'id',
    'name',
    'user_id',
    'unitsList',
    'users_list',
    'shared_data',
  ];

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get shared_data(): SharedGroupData {
    return this._shared_data;
  }

  set shared_data(value: SharedGroupData) {
    this._shared_data = value;
  }

  get unitsList(): string[] {
    if (this._unitsList == null) {
      return [];
    }
    return this._unitsList;
  }

  set unitsList(value: string[]) {
    if (value) {
      this._unitsList = value;
    }
  }

  set unitsListCount(val) {
    this._unitsListCount = val;
  }
  get unitsListCount() {
    return this._unitsListCount;
  }
  get crat(): any {
    return this._crat;
  }

  set crat(value) {
    this._crat = value;
  }
}

export class SharedGroupData extends AppModel {
  private _owner_name: string;
  private _permission: string = 'view_only';
  private _tenant_name: string;

  protected createFields = ['owner_name', 'permission', 'tenant_name'];
  protected updateFields = ['owner_name', 'permission', 'tenant_name'];
  get owner_name(): string {
    return this._owner_name;
  }

  set owner_name(value: string) {
    this._owner_name = value;
  }
  get permission(): string {
    return this._permission;
  }

  set permission(value: string) {
    this._permission = value;
  }
  get tenant_name(): string {
    return this._tenant_name;
  }

  set tenant_name(value: string) {
    this._tenant_name = value;
  }
}
