import { Injectable } from '@angular/core';
import {
  throwError as observableThrowError,
  Subject,
  of,
  ReplaySubject,
  BehaviorSubject,
} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { Pagination, AfaqyHelper, AppConfig } from '../../common/classes';
import { ApiRequestService } from './api-request-service';
import { AfaqyAlertService } from './alert.service';
import { AuthService } from './auth.service';
import { AfaqyResponse } from '../classes';
import { AppModel } from '../../common/classes/app-model';
import { AfaqyAPIResponse } from '../classes/afaqy-response';

import { Observable } from 'rxjs/Rx';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class RootService {
  private _partialLoaded: false;
  private _failedLoading: false;
  public iconsList: any[] = [];
  public optionsList: any;
  public pagination: any;
  protected _resourcesList: any;
  /**
   * TODO: refactor resources Subject from ReplaySubject because it's causing template re render multiple times and refactor all affected modules
   */
  protected _resources: ReplaySubject<any> = new ReplaySubject<any>(1);
  loading: boolean = false;

  public assignedUsers: any;
  public op: string;
  protected sortByKey = '';
  public customActions: string[] = [];
  public filteredResourcesList: any[];
  public filterResourcesApplied: Subject<any> = new Subject<any>();
  public disableListItemAction: any = {
    add: false,
    edit: false,
    copy: false,
    delete: false,
  };
  viewOnMap: BehaviorSubject<any[]> = new BehaviorSubject([]);
  visibleItems: BehaviorSubject<any> = new BehaviorSubject({});
  public finishedLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  requestsArr = [];
  parallelRequests = AppConfig.parallelRequests;
  totalItemsList: number;
  totalItemsTrashedList: number;
  customPermissionKey: string;
  constructor(
    public authService: AuthService,
    protected apiRequest: ApiRequestService,
    protected alertService: AfaqyAlertService,
    protected notificationsService: NotificationsService
  ) {
    this.resourcesList = [];
    this.pagination = new Pagination();
    authService.loadedSession.subscribe((flag) => {
      if (flag) {
        this.resetResources(true);
        this.optionsList = null;
        if (this.autoLoad && authService.isLoggedIn) {
          this.autoLoadResources();
        }
      }
    });
  }

  get IdentifierKey() {
    return 'id';
  }

  get hasNext() {
    if (this.pagination?.pages) {
      return this.pagination.hasNext();
    } else {
      return this.partialLoaded == false;
    }
  }

  get listForSelect() {
    let list = [];
    this.resourcesList.forEach((item) => {
      list.push(item.forList());
    });
    return list;
  }

  getOptionsList(
    prefix: string = '',
    uid: string = '',
    refresh: boolean = false
  ) {
    if (!refresh && this.optionsList) {
      return of(this.optionsList);
    }
    let params = {};
    if (uid) {
      params['user_id'] = uid;
    }
    if (!prefix) {
      prefix = this.routerPrefix();
    }
    return this.apiRequest
      .authPost(this.getFunctionURL('optionsList', prefix), params)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          let optionsList = [];
          if (result.status_code == 200) {
            for (const k in result.data) {
              optionsList.push({ id: k, title: result.data[k] });
            }
          }
          this.optionsList = optionsList;
          return optionsList;
        })
      );
  }

  addToOptionsList(item: any) {
    if (!this.optionsList) {
      return;
    }
    this.optionsList.push({ id: item.id, title: item.titleForList() });
  }

  updateOptionsList(item: any) {
    if (!this.optionsList) {
      return;
    }
    this.optionsList.map((record: any) => {
      if (record['id'] == item['id']) {
        record['title'] = item.titleForList();
      }
      return record;
    });
  }

  removeFromOptionsList(item: any) {
    if (!this.optionsList) {
      return;
    }
    this.optionsList.filter((record: any) => record.id != item.id);
  }

  get limitForPageList(): number {
    return Pagination.defaultLimit;
  }

  get autoLoad() {
    return false;
  }

  routerPrefix(val: string = '') {
    return val ? val : '';
  }

  get pages() {
    return this.pagination;
  }

  get listProjectionFields() {
    return [];
  }

  startAutoload() {
    if (!this.partialLoaded && !this.loading) {
      this.autoLoadResources(1);
    }
  }

  resetResources(newSession: boolean = false) {
    this.failedLoading = false;
    this.partialLoaded = false;
    this.resourcesList = [];
    this.resetFilteredResourcesList();
    this.pushChanges({ action: 'refresh' });
  }
  setListParameters(page, params) {
    params['offset'] = Pagination.getOffset(page, this.limitForPageList);
    params['limit'] = this.limitForPageList;
  }

  public autoLoadResources(page = 1, params = {}, append?: boolean) {
    // TODO: This should be turned to true in order to prevent requesting again if first request is made
    this.loading = true;
    if (this.routerPrefix() == '') {
      return;
    }
    if (append) {
      this.failedLoading = false;
      this.partialLoaded = false;
      this.pushChanges({ action: 'refresh' });
    }
    if (page == 1 && !append) {
      this.resetResources();
    }
    let rp = this.routerPrefix();
    this.setListParameters(page, params);
    this.getLists(params)
      .pipe(map((res) => this.refactorListResponse(res)))
      .subscribe(
        (response: AfaqyResponse) => {
          this.partialLoaded = true;
          this.loading = false;
          this.updateResources(response);
          let requestBody = {
            params: params,
            status: 'pending',
          };

          if (this.parallelRequests) {
            this.parallelRequestsInit(response, requestBody);
          } else {
            this.nonParallelRequestsInit(response, params);
          }
        },
        (error) => {
          this.failedLoading = true;
          this.loading = false;
          let err = new AfaqyResponse();
          err.copyInto(JSON.parse(error));
          //this.pushNotification(err.message, "error", "error");
          this.pushChanges();
        }
      );
  }

  /**
   * Handle normal old logic if the paralleReqests flag is true global or from any standalone service
   * Call APIs in parallel using forkJoin
   * Determine how many pages does an API have to load multiple requests in parallel
   * **/
  parallelRequestsInit(response, requestBody) {
    if (
      response?.pagination?.pages > 1 &&
      this.pagination &&
      this.pagination.hasNext()
    ) {
      const pagesAfterFirstLoad = response?.pagination?.pages - 1;
      for (let i = 1; i <= pagesAfterFirstLoad; i++) {
        this.requestsArr.push(requestBody);
      }
      this.loadDataInParallel();
    } else {
      this.applyAfterLoadResources();
    }
  }

  /**
   * Handle normal old logic if the paralleReqests flag is false global or from any standalone service
   * Call APIs sequentially
   * **/
  nonParallelRequestsInit(response, params) {
    if (this.pagination && this.pagination.hasNext()) {
      this.autoLoadResources(response.pagination.next(), params);
    } else {
      this.applyAfterLoadResources();
    }
  }

  /**
   * validate search text exist in item
   * @param item string to search in it
   * @param searchText string of search text, could be seperated multiple strings by comma ","
   */
  validateSearchTextExistInItem(item, searchText: string): boolean {
    if (searchText.indexOf(',')) {
      let searchTextArray = searchText.split(',');
      const filteredSearchTextArray = searchTextArray.filter((str) => {
        if (str !== '' || str !== undefined) {
          return str;
        }
      });
      return filteredSearchTextArray
        .map((str) => {
          const isFirstCharSpace = str.charAt(0) === ' ';
          if (isFirstCharSpace) {
            str = str.substring(1);
          }
          return str;
        })
        .some((keyword) => {
          return item && item.toLowerCase().indexOf(keyword) > -1;
        });
    } else {
      return item && item.toLowerCase().indexOf(searchText) > -1;
    }
  }

  /**
   * In case of loading APIs in parallel, this method handles the logic using forkJoin
   * After subscribing, updating all lists using UpdateResource()
   * i + 2 => skipping two loops, one for the 0 index, and another one because requests starting from page 2
   * **/
  loadDataInParallel() {
    forkJoin(
      this.requestsArr.map((request: any, i: number) => {
        request['params']['offset'] = Pagination.getOffset(
          i + 2,
          this.limitForPageList
        );
        return this.getLists(request.params).pipe(
          map((res) => this.refactorListResponse(res))
        );
      })
    ).subscribe((requests: any) => {
      requests.map((unit) => this.updateResources(unit));
      this.applyAfterLoadResources();
    });
  }

  refactorListResponse(res) {
    return res;
  }

  applyAfterLoadResources() {
    this.finishedLoading.next(true);
  }

  pushChanges(event: any = {}) {
    this._resources.next(event ? event : {});
  }

  updateResources(response: AfaqyResponse) {
    //this.resourcesList = this.resourcesList.concat(response.list);
    this.setResourcesList(response.list);
    this.pagination = response.pagination;

    this.updateObjectsRelations({ action: 'list', data: response.list });
    this.pushChanges({ action: 'list', data: response.list });
  }

  updateObjectsRelations(data: any = {}) {}

  addToResource(id: string) {
    this.getById(id)
      .pipe(map((res) => this.refactorViewResponse(res)))
      .subscribe((response: AfaqyResponse) => {
        if (response.success) {
          //this.resourcesList = this.resourcesList.concat([response.data]);
          this.setResourcesList([response.data]);
          this.AddOneToList();
          this.updateObjectsRelations({
            action: 'add',
            data: response.data,
          });
          this.addToOptionsList(response.data);
          this.resetFilteredResourcesList();
          this.pushChanges({ action: 'add', data: response.data });
        }
      });
  }

  refactorViewResponse(res) {
    return res;
  }

  addMultiToResource(ids: any) {
    let params = {
      filters: {
        id: {
          value: ids,
          op: 'in',
        },
      },
    };
    this.getLists(params)
      .pipe(map((res) => this.refactorListResponse(res)))
      .subscribe((response: AfaqyResponse) => {
        if (response.success) {
          this.setResourcesList(response.list);
          this.updateObjectsRelations({
            action: 'addMulti',
            data: response.list,
          });
          this.pushChanges({ action: 'addMulti', data: response.list });
        }
      });
    this.pushChanges({ action: 'restore', data: ids });
  }

  removeFromResources(id: any) {
    if (!Array.isArray(id)) {
      id = [id];
    }
    let obj: any;
    for (let i of id) {
      if (
        this.IdentifierKey == 'id' &&
        this._resourcesList[i] &&
        this._resourcesList[i]['id'] == i
      ) {
        obj = this._resourcesList[i];
        delete this._resourcesList[i];
      } else {
        if (this._resourcesList) {
          for (var pk in this._resourcesList) {
            if (this._resourcesList[pk]['id'] == i) {
              obj = this._resourcesList[pk];
              delete this._resourcesList[pk];
            }
          }
        }
      }
      this.updateObjectsRelations({ action: 'remove', data: i });
      this.resetFilteredResourcesList();
      this.pushChanges({ action: 'remove', data: i, object: obj });
    }
  }

  removeForeverFromResources(id: any) {
    if (!Array.isArray(id)) {
      id = [id];
    }
    let obj: any;
    for (let i of id) {
      if (
        this.IdentifierKey == 'id' &&
        this._resourcesList[i] &&
        this._resourcesList[i]['id'] == i
      ) {
        obj = this._resourcesList[i];
        delete this._resourcesList[i];
      } else {
        if (this._resourcesList) {
          for (var pk in this._resourcesList) {
            if (this._resourcesList[pk]['id'] == i) {
              obj = this._resourcesList[pk];
              delete this._resourcesList[pk];
            }
          }
        }
      }
      this.updateObjectsRelations({ action: 'remove-forever', data: id });
    }
    this.pushChanges({
      action: 'remove-forever',
      data: id,
      object: obj,
    });
  }

  updateResourceObject(id: any) {
    this.getById(id)
      .pipe(map((res) => this.refactorViewResponse(res)))
      .subscribe((obj: AfaqyResponse) => {
        this.setResourcesList([obj.data]);
        this.updateObjectsRelations({
          action: 'update',
          data: obj.data,
        });
        this.pushChanges({
          action: 'update',
          data: obj.data,
          relations: false,
        });
      });
  }

  getResourceTitle(id: any) {
    return this.getItemFromResources(id)?.titleForList();
  }

  getItemFromResources(id: any) {
    if (this.IdentifierKey == 'id') {
      if (this._resourcesList[id] && this._resourcesList[id]['id'] == id) {
        return this._resourcesList[id];
      }
    } else {
      const item = this.resourcesList.find(function (item) {
        return item.id === id;
      });
      if (item) {
        return item;
      }
    }
    return this.modelInstance;
  }

  getItemByKey(key: string, val: any) {
    const item = this.resourcesList.find(function (item) {
      return item[key] === val;
    });
    if (item) {
      return item;
    }
    return this.modelInstance;
  }

  getAllItemsByKey(key: string, val: any) {
    return this.resourcesList.filter(function (item) {
      return item[key] === val;
    });
  }

  set partialLoaded(value: any) {
    this._partialLoaded = value;
  }

  get partialLoaded(): any {
    return this._partialLoaded;
  }

  get failedLoading(): any {
    return this._failedLoading;
  }

  set failedLoading(value: any) {
    this._failedLoading = value;
  }

  get resourcesList() {
    let rList = [];
    if (this._resourcesList) {
      for (var pk in this._resourcesList) {
        rList.push(this._resourcesList[pk]);
      }
    }
    if (this.sortByKey) {
      rList.sort((a, b) => {
        if (a[this.sortByKey] > b[this.sortByKey]) {
          return 1;
        } else if (a[this.sortByKey] < b[this.sortByKey]) {
          return -1;
        }
        return 0;
      });
    }
    return rList;
  }

  updateResourceField(id: any, key: string, value: any) {
    this._resourcesList[id][key] = value;
  }
  updateMapVisibility(id, key, value) {}
  set resourcesList(list) {
    let rList = {};
    for (let obj of list) {
      rList[obj[this.IdentifierKey]] = obj;
    }
    this._resourcesList = rList;
    //this._resourcesList = list;
  }

  setResourceObject(obj: any) {
    this._resourcesList[obj[this.IdentifierKey]] = obj;
    // console.log('resourceList: ', this._resourcesList);
  }

  setResourcesList(list: any) {
    for (let obj of list) {
      this.setResourceObject(obj);
    }
  }

  get resources() {
    return this._resources;
  }

  callWindowResize() {
    window.dispatchEvent(new Event('resize'));
  }

  confirm(
    msg: string = 'messages.discard_changes',
    params = null,
    params2 = null
  ) {
    return this.alertService.confirm(msg, params, params2);
  }

  alert(message: any) {
    return this.alertService.alert(message);
  }

  popup(messages: any[]) {
    return this.alertService.popup(messages);
  }

  pushNotification(
    msg: string = '',
    title: string = '',
    type: string = 'success'
  ) {
    // Logger.log('pushNotification', msg, title, type);
    switch (type) {
      case 'error':
        this.notificationsService.error(title, msg);
        break;
      case 'info':
        this.notificationsService.info(title, msg);
        break;
      case 'warn':
        this.notificationsService.warn(title, msg);
        break;
      case 'success':
      default:
        this.notificationsService.success(title, msg);
        break;
    }
  }

  getUserPreferences(module: any, page: any, defaultVal: any = []) {
    return this.authService.preferences(module, page, defaultVal);
  }

  updateUserPreferences(module: any, key: string, data: any) {
    this.authService.updatePreferences(module, key, data);
  }

  doAssignation(url: string, ids: string[], resource_ids: string[]) {
    return this.apiRequest
      .authPost(url, { user_ids: ids, ids: resource_ids })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          reqres.data = { id: result.data.id };
          this.applyAfterUpdate(ids);
          this.fillResponseDetails(reqres, result);
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  getFunctionURL(action: any, prefix: string = '') {
    let url = (prefix ? prefix : this.routerPrefix()) + '/' + action;
    return url;
  }

  assignation(ids: string[], op: any, resource_ids: any, prefix: any) {
    this.applyBeforeAssignation(ids, op);
    return this.doAssignation(
      this.getFunctionURL(op, prefix),
      ids,
      resource_ids
    );
  }

  getLists(params: any) {
    params = this.applyBeforeList(params);
    return this.doGetLists(this.getFunctionURL('lists'), params).pipe(
      map((data: AfaqyResponse) => {
        data.list = this.applyAfterList(data.list);
        this.totalItemsList = data?.pagination?.allCount;
        return data;
      })
    );
  }
  deductByOneList() {
    this.totalItemsList = this.totalItemsList - 1;
  }
  AddOneToList() {
    this.totalItemsList = this.totalItemsList + 1;
  }
  applyAfterListTrashed(list: AppModel[]) {
    return list;
  }

  getTrashedList(params: any) {
    return this.doGetLists(this.getFunctionURL('trashed'), params).pipe(
      map((data: AfaqyResponse) => {
        data.list = this.applyAfterListTrashed(data.list);
        this.totalItemsTrashedList = data?.pagination?.filtersCount;
        data.list.map((item) => {
          if (item.deleted_at) {
            item.deleted_at = AfaqyHelper.timeStampToTimeZoneDateString(
              item.deleted_at,
              this.authService.user.timezone,
              'YYYY-MM-DD HH:mm:ss'
            );
          }
          return item;
        });
        return data;
      })
    );
  }
  deductByOneTrashedList() {
    this.totalItemsTrashedList = this.totalItemsTrashedList - 1;
  }

  getById(id: string, prefix: string = '') {
    this.applyBeforeView(id);
    return this.doGetById(this.getFunctionURL('view', prefix), id);
  }

  getProfile() {
    return this.apiRequest
      .authPost(this.getFunctionURL('profile'), { simplify: 1 })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          if (result.status_code == 200) {
            result = this.mapSimplify(result);
            reqres.data = this.prepareOne(result.data);
            this.applyAfterView(reqres.data);
          }
          this.fillResponseDetails(reqres, result);
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  create(obj: AppModel, user: boolean = true) {
    let params: {} = obj.getCreatePostData();
    params['user_id'] = this.getUserId();
    params = this.applyBeforeCreate(params);
    return this.doCreate(this.getFunctionURL('add'), params);
  }

  update(obj: any) {
    let params: {} = obj.getUpdatePostData();
    params['id'] = obj.id;
    params = this.applyBeforeUpdate(params);
    if (!obj.password) {
      delete params['password'];
    }
    return this.doUpdate(this.getFunctionURL('edit'), params);
  }

  updateProfile(obj: any) {
    let params: {} = obj.getUpdatePostData();
    params = this.applyBeforeUpdate(params);
    if (!obj.password) {
      delete params['password'];
    }
    return this.doUpdateProfile(this.getFunctionURL('edit_profile'), params);
  }

  doUpdateProfile(url: string, obj: any) {
    return this.apiRequest.authPost(url, obj).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        reqres.data = result.data;
        this.fillResponseDetails(reqres, result);
        this.applyAfterUpdate(obj.id);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  delete(id: number, params?: object): Observable<AfaqyResponse> {
    this.applyBeforeDelete(id);
    return this.doDelete(this.getFunctionURL('delete'), id, params);
  }
  archive(id: number, params?: object): Observable<AfaqyResponse> {
    this.applyBeforeDelete(id);
    params = {
      ids: id,
    };
    return this.doDelete(this.getFunctionURL('bulk-delete'), id, params);
  }
  deleteAllForever(id: number, params?: object): Observable<AfaqyResponse> {
    this.applyBeforeDelete(id);
    params = {
      ids: id,
    };
    return this.doDelete(
      this.getFunctionURL('bulk-delete-forever'),
      id,
      params
    );
  }

  deleteForever(id: number) {
    this.applyBeforeDelete(id);
    return this.doDeleteForever(this.getFunctionURL('delete_forever'), id);
  }

  restore(ids: string[]) {
    this.applyBeforeRestore(ids);
    return this.doRestore(this.getFunctionURL('restore'), ids);
  }

  export(type: string, ids: string[]) {
    return this.doExport(this.getFunctionURL('export'), {
      type: type,
      ids: ids,
    });
  }

  downloadTemplate(type: string) {
    return this.doExport(this.getFunctionURL('download-sample'), {
      type: type,
    });
  }

  import(params: any) {
    return this.doImport(this.getFunctionURL('import'), params);
  }

  public serverError(err: any) {
    if (err instanceof Error) {
      try {
        const errorKey = JSON.parse(err.message).message;
        const errorMsg = JSON.parse(err.message);
        if (
          errorKey === 'token_invalid' ||
          errorKey === 'token_expired' ||
          errorMsg === 'token_invalid'
        ) {
          // Redirect to Login
          this.authService.redirectTOLogin('serverError');
        }
        return observableThrowError(err.message);
      } catch (e) {}
    } else if (err.error) {
      const result = err.error;
      if (result && result.error === 'token_expired') {
        // Redirect to Login
        this.authService.redirectTOLogin('serverError');
      }
      let reqres = new AfaqyResponse();
      reqres.status(result.status_code);
      this.fillResponseDetails(reqres, result);
      /* Http Failure Error */
      if (err.message) {
        reqres.message = err.message;
        reqres.status_code = '504';
      }
      /* Http Failure Error */
      return observableThrowError(JSON.stringify(reqres));
    }
    let error = new AfaqyResponse();
    error.message = 'backend-server-error';
    //throw new Error(JSON.stringify(error));
    return observableThrowError(JSON.stringify(error));
  }

  clearDataList() {}

  applyAfterList(objects: AppModel[]) {
    return objects;
  }

  applyAfterObjectList(data) {
    return data;
  }

  applyAfterCreate(id: any) {
    this.clearDataList();
  }

  applyAfterUpdate(id: any) {
    this.clearDataList();
  }

  applyAfterDelete(id: any) {
    this.clearDataList();
    this.removeFromOptionsList({ id: id });
  }

  applyAfterDeleteForever(id) {
    this.clearDataList();
    this.removeFromOptionsList({ id: id });
  }

  applyAfterView(object: AppModel) {}

  applyAfterRestore(ids: any) {
    this.clearDataList();
    this.optionsList = null;
  }

  applyAfterImport(data: any) {
    this.clearDataList();
    this.optionsList = null;
  }

  applyBeforeList(params: any) {
    return params;
  }

  applyBeforeCreate(params: any) {
    return params;
  }

  applyBeforeUpdate(params: any) {
    return params;
  }

  applyBeforeDelete(id: any) {}

  applyBeforeRestore(id: any) {}

  applyBeforeAssignation(id: any, op: any) {}

  applyBeforeView(params: any) {
    return params;
  }

  mapSimplifiedObject(data: any, structure: any) {
    let result: any;
    switch (structure['type']) {
      case 'value':
        result[structure['key']] = data[structure['index']];
        break;
      case 'array':
        result = [];
        if (data && data.length) {
          try {
            data.forEach((item) => {
              let rstructure = JSON.parse(JSON.stringify(structure));
              rstructure['type'] = 'object';
              result.push(this.mapSimplifiedObject(item, rstructure));
            });
          } catch (e) {
            // Logger.error(data, e);
          }
        }
        break;
      case 'object':
        result = {};
        structure['attr'].forEach((column) => {
          if (column['type'] == 'value') {
            result[column['key']] = data ? data[column['index']] : '';
          } else {
            result[column['key']] = this.mapSimplifiedObject(
              data[column['index']],
              column
            );
          }
        });
        break;
    }

    return result;
  }

  mapSimplify(result: any) {
    if (!result['structure']) {
      return result;
    }
    //Logger.log("REQUEST RESULT:", result.structure, result.data);
    result.data = this.mapSimplifiedObject(result.data, result['structure']);
    //Logger.log("Simplify RESULT:", result.data);
    return result;
  }

  doGetLists(url: string, params: any) {
    params['simplify'] = 1;
    params['projection'] = this.listProjectionFields;
    return this.apiRequest.authPost(url, params).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        if (result.status_code == 200) {
          //result = this.mapSimplify(result);
          if (result.pagination) {
            reqres.paginate(result.pagination);
          }
          if (Array.isArray(result.data)) {
            reqres.list = this.prepareList(result.data);
            this.applyAfterList(reqres.list);
          } else {
            reqres.objectList = result.data;
            this.applyAfterObjectList(reqres.objectList);
          }
        }
        this.fillResponseDetails(reqres, result);
        if (reqres.success) {
          return reqres;
        } else {
          throw new Error(JSON.stringify(reqres));
        }
      }),
      catchError((err) => {
        // Logger.error(err);
        return this.serverError(err);
      })
    );
  }

  doGetById(url: string, id: string, simplify: any = 1) {
    return this.apiRequest.authPost(url, { id: id, simplify: simplify }).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        if (result.status_code == 200) {
          result = this.mapSimplify(result);
          reqres.data = this.prepareOne(result.data);
          this.applyAfterView(reqres.data);
        }
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doResponse(reqres: AfaqyResponse) {
    if (reqres.success) {
      return reqres;
    } else {
      throw new Error(JSON.stringify(reqres));
    }
  }

  doCreate(url: string, obj: any) {
    return this.apiRequest.authPost(url, obj).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        if (reqres.success) {
          reqres.data = { id: result.data.id };
          this.applyAfterCreate(result.data.id);
        }
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  getRequest(url: string, obj: any) {
    return this.apiRequest.authGet(url, obj).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        if (reqres.success) {
          reqres.data = { id: result.data.id };
          this.applyAfterCreate(result.data.id);
        }
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doGet(url: string, obj: any) {
    return this.apiRequest.authGet(url, obj).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        if (reqres.success) {
          reqres.data = result.data;
          this.applyAfterCreate(result.data.id);
        }
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doUpdate(url: string, obj: any) {
    return this.apiRequest.authPost(url, obj).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        this.fillResponseDetails(reqres, result);
        this.applyAfterUpdate(obj.id);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doDelete(
    url: string,
    id: number,
    params?: object
  ): Observable<AfaqyResponse> {
    let deleteParams: Object;
    if (params) deleteParams = params;
    else deleteParams = { id: id };
    return this.apiRequest.authPost(url, deleteParams).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        reqres.data = { id: id };
        this.applyAfterDelete(id);
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doDeleteForever(url: string, id: number) {
    return this.apiRequest.authPost(url, { id: id }).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        reqres.data = { id: id };
        this.applyAfterDeleteForever(id);
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doRestore(url: string, ids: string[]) {
    return this.apiRequest.authPost(url, { ids: ids }).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        this.applyAfterRestore(ids);
        this.fillResponseDetails(reqres, result);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  doImport(url: string, params: any) {
    return this.apiRequest.authPost(url, params).pipe(
      map((result: AfaqyAPIResponse) => {
        let reqres = new AfaqyResponse();
        reqres.status(result.status_code);
        reqres.data = result.data;
        this.fillResponseDetails(reqres, result);
        this.applyAfterImport(result.data);
        return this.doResponse(reqres);
      }),
      catchError((err) => this.serverError(err))
    );
  }

  // getImport(url: string, params: any) {
  //     return this.apiRequest.authGet(url, params).pipe(
  //         map((result: AfaqyAPIResponse) => {
  //             let reqres = new AfaqyResponse();
  //             reqres.status(result.status_code);
  //             reqres.data = result.data;
  //             this.fillResponseDetails(reqres, result);
  //             this.applyAfterImport(result.data);
  //             return this.doResponse(reqres);
  //         }),
  //         catchError((err) => this.serverError(err))
  //     );
  // }

  getExportFileName(prms: any) {
    return (
      this.authService.user.username +
      '_' +
      this.routerPrefix() +
      '_' +
      moment().format('YYYY-MM-DD')
    );
  }

  doExport(url: string, params: any) {
    const options = { responseType: 'blob' };
    return this.apiRequest.postExport(url, params, options).pipe(
      map((result: any) => {
        if (params.type === 'excel') params.type = 'xlsx';
        let fileName = this.getExportFileName(params);
        fileName += '.' + params.type;
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(result);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return 'download';
      }),
      catchError((err) => this.serverError(err))
    );
  }

  downloadFile(url: string, params: any) {
    const options = { responseType: 'blob' };
    return this.apiRequest.getRequest(url, params, options).pipe(
      map((result: any) => {
        if (params.type === 'excel') params.type = 'xlsx';
        let fileName = this.getExportFileName(params);
        fileName += '.' + params.type;
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(result);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return 'download';
      }),
      catchError((err) => this.serverError(err))
    );
  }

  get modelInstance(): any {
    return new AppModel();
  }

  prepareList(data: any) {
    let list = [];
    for (let obj of data) {
      let object = this.modelInstance;
      object.copyInto(obj);
      list.push(object);
    }
    return list;
  }

  prepareOne(data: any) {
    let object = this.modelInstance;
    object.copyInto(data);
    return object;
  }

  fillResponseDetails(reqres: any, result: any) {
    reqres.message = result.message;
    reqres.status_code = result.status_code;
    reqres.errors = result.errors || [];
    reqres.extra = result.extra || [];
  }

  getUserId() {
    return this.authService.userID;
  }

  icons(): Observable<any[]> {
    return this.apiRequest.authPost(this.getFunctionURL('icons'), {}).pipe(
      map((result: AfaqyAPIResponse) => {
        let list = [];
        if (result.status_code == 200) {
          const baseBath = result.data.base;
          for (let k of result.data.list) {
            list.push({ key: k, url: baseBath + '/' + k });
          }
        }
        this.iconsList = list;
        return list;
      })
    );
  }

  checkAssignationPermissions() {
    return this.checkPermissions(this.routerPrefix() + '-assign');
  }

  checkPermissions(permissionKey: string, checkAdmin = true): boolean {
    const kr = this.authService.checkPermissions(permissionKey);
    if (!checkAdmin || kr) {
      return kr;
    }
    return this.authService.checkPermissions(permissionKey + '_admin');
  }

  get cid() {
    return this.routerPrefix();
  }

  get cidPermissionKey() {
    return this.customPermissionKey ? this.customPermissionKey : this.cid;
  }

  gridColumns(trashed: boolean = false): any[] {
    return [];
  }

  get listingIcon() {
    return '';
  }

  updateFlag(prms: any) {
    //request
    return this.apiRequest
      .authPost(this.getFunctionURL('update_flag'), prms)
      .pipe(
        map((result: AfaqyAPIResponse) => {
          let reqres = new AfaqyResponse();
          reqres.status(result.status_code);
          return this.doResponse(reqres);
        }),
        catchError((err) => this.serverError(err))
      );
  }

  isResourceExist(key: string) {
    return this._resourcesList[key] ? true : false;
  }

  getActiveResourcesList(): any[] {
    const filteredIds: string[] = AfaqyHelper.getListIds(
      this.filteredResourcesList
    );
    let items: any[] = [...this.resourcesList];
    if (
      filteredIds &&
      filteredIds.length > 0 &&
      filteredIds.length < items.length
    ) {
      items = this.resourcesList.filter((item: any) => {
        return filteredIds.includes(item.id);
      });
    }
    return items;
  }

  applyCustomAction(action: string, itemId: string) {}

  public resetFilteredResourcesList() {
    this.filteredResourcesList = [];
  }

  /**
   * If filter applied on the list return ids of filteredResourcesList, else return [].
   */
  public getFilteredIds(): string[] {
    let ids: string[] = [];
    if (
      this.filteredResourcesList &&
      this.resourcesList &&
      this.filteredResourcesList.length < this.resourcesList.length
    )
      ids = AfaqyHelper.getListIds(this.filteredResourcesList);
    return ids;
  }
}
