<!--<input  class="form-control form-control-sm" [min]="minDate" type="date"-->
<!--        [(ngModel)]="formattedDate" (ngModelChange)="onValueChange($event)">-->

<div
  class="form-control form-control-sm input-container"
  [ngClass]="{ disabled: isDisabled }"
>
  <input
    [matDatepicker]="picker"
    [min]="minDate"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
