import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AfaqyValidation } from 'app/common/afaqy-validation';
import { UnitServiceItem } from 'app/modules/units/models';
import { UnitService, UnitServicesService } from 'app/modules/units/services';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';
import { AfaqyHelper, Message } from './../../../../common/classes';
import { AfaqyResponse } from './../../../../core/classes';

@Component({
  selector: 'unit-service-form',
  templateUrl: './unit-form-service-form.component.html',
  styleUrls: ['./unit-form-service-form.component.scss'],
})
export class UnitFormServicesFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  alive: boolean = true;
  @Input() object: UnitServiceItem;
  @Input() unitID: string;
  @Input() isView: boolean;
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();

  title: string = '';
  form: UntypedFormGroup;
  message: Message;
  cid = 'units-service-';

  posting = false;
  loading = false;
  intervalErrorMsg: any;
  forceDeactivate: boolean = false;
  unitObject: any;

  constructor(
    public translate: TranslateService,
    protected fb: UntypedFormBuilder,
    public service: UnitServicesService,
    public unitService: UnitService
  ) {
    this.object = new UnitServiceItem();
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillFormValues();
  }

  get serviceTypeGroup() {
    return this.fb.group({
      status: [false],
      interval: [0, AfaqyValidation.numberValidator],
      last: [0, AfaqyValidation.numberValidator],
      left: [],
      left_num: ['', AfaqyValidation.numberValidator],
    });
  }

  get form_fields(): any {
    return {
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      update_last: [],
      odometer: this.serviceTypeGroup,
      engine_hours: this.serviceTypeGroup,
      days: this.fb.group({
        status: [false],
        interval: [0, AfaqyValidation.numberValidator],
        last: [],
        left: [],
        left_num: ['', AfaqyValidation.numberValidator],
      }),
      date: this.fb.group({
        status: [false],
        date: [],
        left: [],
        left_num: ['', AfaqyValidation.numberValidator],
      }),
    };
  }

  updateLast() {
    this.form.controls['update_last'].setValue(
      !this.form.controls['update_last'].value
    );
  }

  updateServiceTypeField(event: any, key: any) {
    this.updateServiceTypesStatus(key, event.target.checked);
  }

  updateServiceTypesStatus(key: any, status: any) {
    ['interval', 'last', 'left', 'date'].forEach((ctl) => {
      if (this.form.controls[key]['controls'].hasOwnProperty(ctl)) {
        if (status) {
          this.form.controls[key]['controls'][ctl].enable();
        } else {
          this.form.controls[key]['controls'][ctl].disable();
        }
      }
    });
  }

  updateServiceLeftStatusField(event: any, key: any) {
    this.updateServiceLeftStatus(key, event.target.checked);
  }

  updateServiceLeftStatus(key: any, status: any) {
    ['left_num'].forEach((ctl) => {
      if (this.form.controls[key]['controls'].hasOwnProperty(ctl)) {
        if (status) {
          this.form.controls[key]['controls'][ctl].enable();
        } else {
          this.form.controls[key]['controls'][ctl].disable();
        }
      }
    });
  }

  ngOnInit() {
    this.message = new Message();
    this.fillFormValues();
    this.unitObject = this.unitService.getUnitDetails(this.unitID);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  applySideEffects() {
    this.updateServiceTypesStatus(
      'odometer',
      this.object.odometer ? this.object.odometer.status : false
    );
    this.updateServiceLeftStatus(
      'odometer',
      this.object.date ? this.object.odometer.left : false
    );

    this.updateServiceTypesStatus(
      'engine_hours',
      this.object.engine_hours ? this.object.engine_hours.status : false
    );
    this.updateServiceLeftStatus(
      'engine_hours',
      this.object.date ? this.object.engine_hours.left : false
    );

    this.updateServiceTypesStatus(
      'days',
      this.object.days ? this.object.days.status : false
    );
    this.updateServiceLeftStatus(
      'days',
      this.object.date ? this.object.days.left : false
    );

    this.updateServiceTypesStatus(
      'date',
      this.object.date ? this.object.date.status : false
    );
    this.updateServiceLeftStatus(
      'date',
      this.object.date ? this.object.date.left : false
    );

    if (this.object.date && this.object.date['date']) {
      this.dates.date = new Date(this.object.date['date']);
    } else {
      this.dates.date = null;
    }
    if (this.object.days && this.object.days['last']) {
      this.dates.days = new Date(this.object.days['last']);
    } else {
      this.dates.days = null;
    }
  }

  isEditUnit() {
    return this.unitID;
  }

  canDeactivate() {
    if (this.forceDeactivate || this.form.pristine) {
      return true;
    }
    return this.service.confirm();
  }

  fillFormValues() {
    let fobj = {};
    if (this.object)
      for (let field in this.form_fields) {
        fobj[field] = this.object[field];
      }
    this.form.reset(fobj);
    this.applySideEffects();
  }

  createForm() {
    this.form = this.fb.group(this.form_fields);
    this.applySideEffects();
    this.forceDeactivate = false;
  }

  revert() {
    this.fillFormValues();
  }

  reset() {
    this.fillFormValues();
  }

  modalClose($event) {
    this.closeForm.next($event);
  }

  prepareSave(): UnitServiceItem {
    const formModel = this.form.value;
    const saveObj = this.service.modelInstance;
    saveObj.id = this.object.id;
    saveObj.unit_id = this.unitID;
    for (let field in this.form_fields) {
      saveObj[field] = formModel[field] as string;
    }
    saveObj.setServiceType('odometer', saveObj['odometer']);
    saveObj.setServiceType('engine_hours', saveObj['engine_hours']);
    saveObj.setServiceDateType('days', saveObj['days']);
    saveObj.setServiceDateType('date', saveObj['date']);
    return saveObj;
  }

  afterFail(err: any) {
    this.posting = false;
    let error = new AfaqyResponse();
    error.copyInto(JSON.parse(err));
    let errorsList = error.errors;
    AfaqyHelper.setFGErrors(this.form, errorsList);
    this.message.type = 'danger';
    this.message.message = error.message || 'please-try-again';
    AfaqyHelper.calcModalHeight(true);
  }

  afterSuccess(msg = {}) {
    this.posting = false;
    this.forceDeactivate = true;
    this.modalClose({ msg: msg, type: 'success', success: true });
    AfaqyHelper.calcModalHeight(false);
  }

  updateServicesList() {
    let service = this.object;
    let unit = this.unitService.getUnitDetails(this.object.unit_id);
    let unitServicesList = unit.services;
    let updatedServicesList = unitServicesList.filter(
      (sens) => sens.id != service.id
    );
    updatedServicesList.push(service);
    unit.services = updatedServicesList;
    this.unitService.setResourceObject(unit);
  }

  saveToDB() {
    this.object = this.prepareSave();
    this.posting = true;
    this.message.clear();
    this.intervalErrorMsg = '';
    if (this.object.id) {
      this.service
        .update(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: () => {
            this.updateList.next({ object: this.object });
            //this.service.updateResourceObject(this.object.id);
            this.updateServicesList();
            this.afterSuccess('notifications.unit_services.updated');
          },
          error: (error) => {
            let errors = JSON.parse(error);
            if ('intervalError' in errors._errors) {
              this.intervalErrorMsg = this.translate.instant(
                'units.unit_services.interval_error'
              );
            }
            this.afterFail(error);
          },
        });
    } else {
      this.service
        .create(this.object)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: AfaqyResponse) => {
            this.object.id = response.data.id;
            this.updateList.next({ object: this.object });
            this.updateServicesList();
            this.afterSuccess('notifications.unit_services.added');
          },
          error: (error) => {
            let errors = JSON.parse(error);
            if ('intervalError' in errors._errors) {
              this.intervalErrorMsg = this.translate.instant(
                'units.unit_services.interval_error'
              );
            }
            this.afterFail(error);
          },
        });
    }
  }

  isValidServiceValues() {
    if (
      !this.form.controls['odometer'].value.status &&
      !this.form.controls['engine_hours'].value.status &&
      !this.form.controls['days'].value.status &&
      !this.form.controls['date'].value.status
    ) {
      this.intervalErrorMsg = this.translate.instant(
        'units.unit_services.interval_error'
      );
      return false;
    }
    return true;
  }

  onSubmit() {
    if (!this.form.valid || !this.isValidServiceValues()) {
      AfaqyHelper.touchAll(this.form);
      return;
    }
    if (this.isEditUnit()) {
      this.saveToDB();
    } else {
      this.object = this.prepareSave();
      this.updateList.next({ object: this.object });
    }
  }

  dates = {
    date: null,
    days: null,
  };
}
