import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';

@Component({
  selector: 'afaqy-control',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AfaqyControl),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfaqyControl),
      multi: true,
    },
  ],
})
export class AfaqyControl implements OnChanges {
  writing = false;
  propagateChange: any = () => {};
  validateFn: any = () => {};
  onTouched: any = () => {};
  ngOnChanges(inputs) {
    this.pushValue();
  }

  pushValue() {
    if (!this.writing) {
      this.propagateChange();
    }
  }

  // Core ControlValueAccessor methods
  writeValue(value: any): void {}
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
