<div [formGroup]="form">
  <div class="row form-group" *ngIf="!hideButtons">
    <div class="col-12 col-md-12 reports-fix">
      <div
        class="d-flex btn-group w-100 mb-1"
        role="group"
        aria-label="Basic example"
        [attr.testid]="'chart-date'"
      >
        <button
          type="button"
          [attr.testId]="routerPrefix + '-today'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'today'"
          (click)="updateDate('today')"
        >
          <span>{{ cid + '.today' | translate }}</span>
        </button>
        <button
          type="button"
          [attr.testId]="routerPrefix + '-yesterday'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'yesterday'"
          (click)="updateDate('yesterday')"
        >
          <span>{{ cid + '.yesterday' | translate }}</span>
        </button>
        <button
          type="button"
          [attr.testId]="routerPrefix + '-week'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'weeks'"
          (click)="updateDate('weeks')"
        >
          <span>{{ cid + '.week' | translate }}</span>
        </button>
        <button
          type="button"
          [attr.testId]="routerPrefix + '-month'"
          class="col customPadding btn btn-outline-primary"
          [class.active]="period == 'months'"
          (click)="updateDate('months')"
        >
          <span>{{ cid + '.month' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <!-- INTERVAL -->
  <div class="row mbx-9">
    <div class="col-md-4 labels d-flex align-items-center">
      <div class="form-group my-1">
        <label class="form-control-label"
          >{{ (cid + '.date_range' | translate) + ' ' + ':' }}
        </label>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['interval']?.dirty ||
            form?.controls['interval']?.touched) &&
          form?.controls['interval']?.errors
        "
      >
        <select
          class="form-control form-control-sm"
          formControlName="interval"
          (change)="toggleInterval(true)"
          [attr.testid]="routerPrefix + '-interval'"
        >
          <option *ngFor="let i of intervals" value="{{ i.val }}" translate>
            {{ i.txt }}
          </option>
        </select>
        <control-errors [ctl]="form?.controls['interval']"></control-errors>
      </div>
    </div>
  </div>
  <!-- END INTERVAL -->
  <div class="row mbx-9" [class.hidden]="showInterval">
    <div class="offset-4 col">
      <div class="form-group my-1">
        <wj-input-number
          [attr.testid]="routerPrefix + '-interval_count'"
          formControlName="interval_count"
          [min]="1"
          [max]="maxPreviousValue[form.get('interval_unit').value]"
          format="n0"
          [step]="1"
          (valueChanged)="onIntervalCountChanged()"
        >
        </wj-input-number>
      </div>
    </div>
    <div class="col pl-0">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['interval_unit']?.dirty ||
            form?.controls['interval_unit']?.touched) &&
          form?.controls['interval_unit']?.errors
        "
      >
        <select
          class="form-control form-control-sm"
          formControlName="interval_unit"
          [attr.testid]="routerPrefix + '-interval_unit'"
          (change)="onIntervalCountChanged()"
        >
          <option
            *ngFor="let iu of intervalUnits"
            value="{{ iu.val }}"
            translate
          >
            {{ iu.txt }}
          </option>
        </select>
        <control-errors
          [ctl]="form?.controls['interval_unit']"
        ></control-errors>
      </div>
    </div>
  </div>
  <div class="row mbx-9" [class.hidden]="showInterval">
    <div class="form-group-container">
      <div class="form-group my-1">
        <div
          class="checkbox-squared d-flex align-items-center justify-content-end"
        >
          <input
            type="checkbox"
            id="checkbox-including_current"
            formControlName="including_current"
            [attr.testid]="routerPrefix + '-including_current'"
          />
          <label for="checkbox-including_current"></label>
          <span>{{ cid + '.including_current' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="timeDiffToggle">
      <div class="time-diff alert alert-warning" role="alert">
        <span>
          {{ 'date_calendar.time_diff_msg' | translate }}
          {{ maxIntervalCount }}
          {{ 'date_calendar.' + maxIntervalUnit | translate }}
        </span>
      </div>
    </div>
  </div>
  <!-- FROM -->
  <div class="row mbx-9" [class.hidden]="!showInterval">
    <div class="col-md-4 labels d-flex align-items-center">
      <div class="form-group my-1">
        <label class="form-control-label">{{
          (cid + '.from' | translate) + ' ' + ':'
        }}</label>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['from']?.dirty || form?.controls['from']?.touched) &&
          form?.controls['from']?.errors
        "
      >
        <p-calendar
          formControlName="from"
          showTime="true"
          showIcon="true"
          monthNavigator="true"
          showButtonBar="true"
          clearButtonStyleClass="d-none"
          [locale]="calendarLocale[currentLang]"
          [maxDate]="maxDate"
          [minDate]="minDate"
          hourFormat="24"
          (onSelect)="onFromDateValueChanged($event)"
          (onInput)="onFromDateValueChanged($event)"
          [attr.testid]="routerPrefix + '-from'"
          readonlyInput="true"
          appendTo="body"
          dateFormat="yy-mm-dd"
          #from
        >
        </p-calendar>
        <control-errors
          *ngIf="!timeDiffToggle"
          [ctl]="form.controls['from']"
        ></control-errors>
      </div>
    </div>
  </div>
  <!-- END FROM -->
  <!-- TO -->
  <div class="row mbx-9" [class.hidden]="!showInterval">
    <div class="col-md-4 labels d-flex align-items-center">
      <div class="form-group my-1">
        <label class="form-control-label">{{
          (cid + '.to' | translate) + ' ' + ':'
        }}</label>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="form-group my-1"
        [class.has-danger]="
          (form?.controls['to']?.dirty || form?.controls['to']?.touched) &&
          form?.controls['to']?.errors
        "
      >
        <p-calendar
          formControlName="to"
          readonlyInput="true"
          showTime="true"
          showIcon="true"
          monthNavigator="true"
          showButtonBar="true"
          clearButtonStyleClass="d-none"
          [locale]="calendarLocale[currentLang]"
          #calendarTo
          hourFormat="24"
          [attr.testid]="routerPrefix + '-to'"
          [minDate]="from.value"
          appendTo="body"
          [maxDate]="maxDate"
          (onClose)="setUserTimeAndMaxDate()"
          (onSelect)="onToDateValueChanged($event)"
          dateFormat="yy-mm-dd"
        ></p-calendar>
        <control-errors
          *ngIf="!timeDiffToggle"
          [ctl]="form.controls['to']"
        ></control-errors>
      </div>
    </div>
    <div class="col-12" *ngIf="timeDiffToggle">
      <div class="time-diff alert alert-warning" role="alert">
        <span>
          {{ 'date_calendar.time_diff_msg' | translate }}
          {{ maxIntervalCount }}
          {{ 'date_calendar.' + maxIntervalUnit | translate }}
        </span>
      </div>
    </div>
  </div>
  <!-- END TO -->
</div>
