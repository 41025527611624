import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import {
  AfaqyAlertService,
  ApiRequestService,
  AuthService,
  RootService,
} from '../../../core/services';
import { UnitSharedGroup } from '../models';

@Injectable({ providedIn: 'root' })
export class UnitSharedGroupService extends RootService {
  constructor(
    authService: AuthService,
    apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    this.disableListItemAction = {
      copy: true,
    };
  }

  get limitForPageList() {
    return 0;
  }
  updateObjectsRelations(updates) {
    if (updates['action'] == 'update' || updates['action'] == 'add') {
      updates['data'].unitsListCount = updates['data'].unitsList?.length;
      this.setResourcesList([updates['data']]);
    }
  }

  setIds(params) {
    params['ids'] = params['unitsList'];
    delete params['unitsList'];
    if (params['ids'].length == 0) {
      delete params['ids'];
    }
    return params;
  }
  applyBeforeUpdate(params) {
    return this.setIds(params);
  }

  applyBeforeCreate(params) {
    return this.setIds(params);
  }
  refactorListResponse(res: any) {
    res.list.map((x) => {
      x.unitsListCount = x.unitsList?.length;
      return x;
    });
    return res;
  }

  getList() {
    let glist = [];
    for (const g of this.resourcesList) {
      // if (true || !g.parent_id) {
      glist.push({ id: g.id, name: g.name });
      // }
    }
    return glist;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'shared_unit_groups';
  }

  get modelInstance() {
    return new UnitSharedGroup();
  }

  gridColumns(trashed = false) {
    let allcols = [
      {
        header: 'unit_shared_groups.group_name',
        minWidth: 100,
        width: '*',
        colValue: 'name',
        active: true,
        immutable: true,
        default: true,
      },
      {
        header: 'unit_shared_groups.units_count',
        minWidth: 75,
        width: '*',
        colValue: 'unitsListCount',
        active: false,
        default: true,
      },
      {
        header: 'unit_shared_groups.owner_name',
        minWidth: 100,
        width: '*',
        colValue: 'shared_data.owner_name',
        active: false,
        default: true,
      },
      {
        header: 'unit_shared_groups.tenant_name',
        minWidth: 75,
        width: '*',
        colValue: 'shared_data.tenant_name',
        active: false,
        default: true,
      },
      {
        header: 'unit_shared_groups.creation_date',
        minWidth: 75,
        width: '*',
        colValue: 'crat',
        active: false,
        default: true,
      },
      {
        header: 'unit_shared_groups.access_permission',
        minWidth: 75,
        width: '*',
        colValue: 'shared_data.permission',
        active: false,
        default: true,
      },
      {
        header: 'actions',
        minWidth: 60,
        width: 100,
        colValue: 'actions',
        active: false,
        default: true,
      },
    ];

    return allcols;
  }
}
