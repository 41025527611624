import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { of, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { ZonesService } from '../../zones/services/zones.service';
import { MarkersService } from '../../markers/services/markers.service';
import {
  ApiRequestService,
  AfaqyAlertService,
  RootService,
  AuthService,
} from '../../../core/services';
import { TailerService } from './../../tailers/services';
import { DriverService } from './../../drivers/services';
import { StatusService } from './../../status/services';
import { UnitGroupService } from './unit_group.service';
import { MonitoringStatus } from '../services/monitoring-status';

import { AfaqyAPIResponse } from '../../../core/classes';
import { AppConfig, AfaqyHelper, AppModel } from '../../../common/classes';

import { UnitLastUpdate } from '../models/unit-last-update';
import { Unit, UnitTracking, ServiceType } from './../models';
import { UnitServiceItem } from '../models';

import { Driver } from '../../drivers/models';
import { Tailer } from '../../tailers/models';
import { BookLogService } from '../../../core/services/book-log.service';
import { SensorColor } from './sensor-color';
import { PusherSocketService } from 'app/core/services/pusher-socket.service';
import { CustomFieldsService } from 'app/modules/custom-fields/custom-fields.service';
import { CustomField } from 'app/modules/custom-fields/models';

@Injectable({ providedIn: 'root' })
export class UnitService extends RootService {
  updatedListIds: Subject<string[]> = new Subject();
  public allUnitsLoaded: boolean = false;
  public trackALLUnits: boolean = true;
  public removeALL: boolean = false;
  public resourceTrackingDetails: any = {};

  public groupedUnits: ReplaySubject<any[]> = new ReplaySubject();
  public unitDriverBinding$: ReplaySubject<any> = new ReplaySubject(null);
  private _trackingObserve: Subject<any[]> = new Subject<any>();
  private _trackedResources: any;
  private customFieldsList: any;
  private IntervalMonitringID: any;
  public stats: any = {
    count: 0,
    monitored: 0,
    moving: 0,
    stopped: 0,
  };
  processId: string;
  copyInfoData: any;
  showCopyInfoSpinner: boolean = false;

  constructor(
    public authService: AuthService,
    public apiRequest: ApiRequestService,
    alert: AfaqyAlertService,
    notificationsService: NotificationsService,
    private tailerService: TailerService,
    private driverService: DriverService,
    private unitGroupService: UnitGroupService,
    protected translateService: TranslateService,
    private statusService: StatusService,
    private zoneService: ZonesService,
    private markerService: MarkersService,
    private bookLog: BookLogService,
    private pusherSocketService: PusherSocketService,
    public customFieldsService: CustomFieldsService
  ) {
    super(authService, apiRequest, alert, notificationsService);
    // this.authService.loadedSession.subscribe({
    //   next: (flag) => {
    //     if (flag) {
    if (!AppConfig.isCMS) {
      // this.startAutoload();

      this.resetStats();
      this.trackALLUnits = this.authService.preferences(
        'monitoring',
        'trackALLUnits',
        true
      );
      this.removeALL = this.authService.preferences(
        'monitoring',
        'removeALLUnits',
        false
      );
    }
    //   },
    // });

    this.driverService.resources.subscribe({
      next: (updates) => {
        this.updateUnitDrivers(updates);
      },
    });
    this.tailerService.resources.subscribe({
      next: (updates) => {
        this.updateUnitTailers(updates);
      },
    });
    this.statusService.resources.subscribe((updates) => {
      this.updateUnitStatus(updates);
    });

    this.unitGroupService.resources.subscribe({
      next: (updates) => {
        this.updateGroupedUnits(updates);
      },
    });
    this.disableListItemAction = {
      add: false,
      edit: true,
      copy: true,
      delete: true,
    };

    this.resources.subscribe({
      next: (updates) => {
        this.updateGroupedUnits(updates);
      },
    });

    this.unitGroupService.finishedLoading.subscribe({
      next: (finished) => {
        if (finished) {
          this.updateGroupedUnits({});
        }
      },
    });
  }

  /**
   * on update of units or unit groups this method updates the
   * groupedUnits Reply Subject with the new values
   * @param updates resources of units or unit groups
   */
  updateGroupedUnits(updates: any) {
    // if(updates.action == "list"){
    let uList = [];
    let units = of(this.resourcesList);
    units.subscribe((items) => {
      items.forEach((item) => {
        if (item.groupsList.length) {
          item.groupsList.forEach((groupid) => {
            let newitem = item.clone();
            newitem['group_id'] = groupid;
            newitem['group_name'] =
              this.unitGroupService.getItemFromResources(groupid).name;
            uList.push(newitem);
          });
        } else {
          let newitem = item.clone();
          newitem['group_id'] = 0;
          newitem['group_name'] = 'Un-grouped';
          uList.push(newitem);
        }
      });
      this.groupedUnits.next(uList);
    });
    // }
  }

  resetStats() {
    this.stats = {
      count: 0,
      monitored: 0,
      moving: 0,
      stopped: 0,
    };
  }

  showALLUnits(status: any) {
    this.trackALLUnits = status;
    this.updateUserPreferences('monitoring', 'trackALLUnits', status);
    if (status) {
      this.removeALLUnits(false);
      this.updateUserPreferences('monitoring', 'deletedGroupIds', []);
      this.setTrackedResourcesToALL();
    }
  }

  removeALLUnits(status: any) {
    this.updateUserPreferences('monitoring', 'removeALLUnits', status);
    this.removeALL = status;
    if (status) {
      this.showALLUnits(false);
    }
  }

  get trackedResources() {
    let trackedUnitIDs = [];
    if (this.trackALLUnits) {
      trackedUnitIDs = this.AllUnitsIDs;
      this.stats.monitored = this.stats.count;
    } else if (this.hasTrackedResources()) {
      trackedUnitIDs = this._trackedResources;
    } else {
      trackedUnitIDs = this.authService.preferences(
        'monitoring',
        'trackedResources',
        []
      );
      if (!this.removeALL && trackedUnitIDs.length == 0) {
        this.showALLUnits(true);
        trackedUnitIDs = this.AllUnitsIDs;
        this.stats.monitored = this.stats.count;
      }
    }
    return trackedUnitIDs;
  }

  /** Check _trackedResources value, return true whether its type array and has value or object and has value, else return false. */
  private hasTrackedResources(): boolean {
    if (
      this._trackedResources &&
      this._trackedResources instanceof Array &&
      this._trackedResources.length
    ) {
      return true;
    } else if (
      this._trackedResources &&
      this._trackedResources instanceof Object &&
      Object.keys(this._trackedResources).length
    ) {
      return true;
    }
    return false;
  }

  set trackedResources(unitIDs: any) {
    unitIDs = unitIDs.filter((uid: string) => {
      return Object.keys(this._resourcesList).includes(uid);
    });
    this._trackedResources = unitIDs;
    if (unitIDs.length) {
      this.removeALLUnits(false);
    }
    this.updateUserPreferences('monitoring', 'trackedResources', unitIDs);
    let visibleResources = [];
    let followedResources = [];
    this.AllUnitsIDs.forEach((unitID) => {
      if (!unitIDs.includes(unitID)) {
        followedResources.splice(followedResources.indexOf(unitID), 1);
        visibleResources.splice(visibleResources.indexOf(unitID), 1);
        this.updateUnitTrackingDetails(unitID, false, 'follow');
        this.updateUnitTrackingDetails(unitID, false, 'visible');
      }
      if (
        this.resourceTrackingDetails[unitID] &&
        this.resourceTrackingDetails[unitID]['visible']
      ) {
        visibleResources.push(unitID);
      }
      if (this.resourceTrackingDetails[unitID]['follow']) {
        followedResources.push(unitID);
      }
    });
    this.updateUserPreferences(
      'monitoring',
      'visibleResources',
      visibleResources
    );
    this.updateUserPreferences(
      'monitoring',
      'followedResources',
      followedResources
    );

    this.pushTracking({ refresh: true });
    this.pushTracking({
      event: 'visible',
      ids: visibleResources,
      checked: true,
    });
    this.stats.monitored = unitIDs.length;
  }

  get listProjectionFields() {
    return [
      'basic',
      'groups',
      'last_update',
      'custom_fields',
      'counters',
      'job_order',
      'sensors',
      'services',
      'commands',
      'driver_behavior',
    ];
  }

  get tracking(): Subject<any[]> {
    return this._trackingObserve;
  }

  get AllUnitsIDs() {
    return Object.keys(this._resourcesList);
  }

  get trackingObjects(): Unit[] {
    let trackedUnitsIds = this.trackedResources;
    let TrackedObjects = [];
    for (var tid of trackedUnitsIds) {
      if (this._resourcesList[tid]) {
        TrackedObjects.push(this._resourcesList[tid]);
      }
    }
    return TrackedObjects;
  }

  get modelInstance() {
    return new Unit();
  }

  get listingIcon() {
    return 'afaqy-icon-units';
  }

  resetResources(newSession: boolean = false) {
    super.resetResources();
    this.allUnitsLoaded = false;
  }

  applyAfterLoadResources() {
    this.allUnitsLoaded = true;
    this.finishedLoading.next(true);
    this.authService.userUnitsLoaded.next('');
    this.trackedResources = this.trackedResources;
    this.updateStats();

    this.updateMonitoredObjects();
    this.IntervalMonitringID = setInterval(() => {
      this.updateMonitoredObjects();
    }, 60000);
  }

  applyBeforeUpdate(params: any) {
    if (!this.authService.checkPermissions('drivers-setUnitDriver')) {
      delete params['driver_id'];
    }
    if (!this.authService.checkPermissions('tailers-setUnitTailer')) {
      delete params['tailer_id'];
    }
    if (!this.authService.checkPermissions('units-set_status')) {
      delete params['status_id'];
    }
    if (!this.authService.checkPermissions('units-deviceAdmin')) {
      delete params['imei'];
      delete params['device'];
      delete params['device_password'];
      delete params['device_serial'];
      delete params['sim_number'];
      delete params['sim_serial'];
    }
    return params;
  }

  getUnitDefaultParameters(id: string) {
    return this.apiRequest
      .authPost(this.getFunctionURL('getUnitDefaultParameters'), { id: id })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            return result.data;
          }
          return [];
        }),
        catchError((err) => this.serverError(err))
      );
  }

  setResourceObject(obj: any) {
    obj.last_update.acc = obj.acc;
    super.setResourceObject(obj);
    this.checkTrackingDetails(obj.id);
    this.stats.count = Object.keys(this._resourcesList).length;
    this.trackedResources;
  }

  updateStats() {
    let moving = 0;
    let stopped = 0;
    for (var pk in this._resourcesList) {
      const unit = this.resourceTrackingDetails[pk];
      unit['sensors'] = new SensorColor(this._resourcesList[pk]);
      // console.log(unit['sensors']);
      if (!unit.connection_state) {
        continue;
      }
      if (
        unit.motion_state == MonitoringStatus.MotionState.MovingON ||
        unit.motion_state == MonitoringStatus.MotionState.MovingOFF
      ) {
        moving++;
      } else if (
        unit.motion_state == MonitoringStatus.MotionState.StationaryON ||
        unit.motion_state == MonitoringStatus.MotionState.StationaryOFF
      ) {
        stopped++;
      }
    }
    this.stats.moving = moving;
    this.stats.stopped = stopped;
  }

  getResourceTrackingDetails(): {} {
    return this.resourceTrackingDetails;
  }

  setResourceTrackingDetails(value: {}) {
    this.resourceTrackingDetails = value;
  }

  getUnitTrackingDetails(id: any): {} {
    return this.resourceTrackingDetails[id];
  }

  getUnitDetails(id): Unit {
    return this._resourcesList[id];
  }

  getUnitName(id: any): Unit {
    return this._resourcesList[id].name;
  }

  getUnitLastUpdate(id: any): UnitLastUpdate {
    return this._resourcesList[id]?.last_update;
  }

  updateUnitTrackingDetails(id: any, value, key = '') {
    if (this.resourceTrackingDetails[id]) {
      if (key) {
        this.resourceTrackingDetails[id][key] = value;
      } else {
        this.resourceTrackingDetails[id].copyInto(value);
      }
    }
    return this.resourceTrackingDetails[id];
  }

  checkTrackingDetails(uid: any) {
    if (!this.resourceTrackingDetails[uid]) {
      this.resourceTrackingDetails[uid] = new UnitTracking();
    }
    // Follow
    this.updateUnitTrackingDetailsFromPreferences(
      uid,
      'follow',
      'followedResources'
    );
    // Visible
    this.updateUnitTrackingDetailsFromPreferences(
      uid,
      'visible',
      'visibleResources'
    );

    //Connection State
    this.checkTrackingUnitConnectionState(uid);
    //Motion State
    this.checkTrackingUnitMotionState(uid);
    //Data Accuracy
    this.checkTrackingUnitDataAccuracy(uid);
  }

  checkTrackingUnitConnectionState(uid: any) {
    let data = this._getUnitDataPrams(uid, 'connection_state');
    let value = 0;
    if (data.duration == 0) {
      value = data.lastUpdate.status === 'online' ? 1 : 0;
    } else if (data.lastUpdate.valid) {
      value = this._checkWithDuration(data.duration, data.lastUpdate.dts);
    }
    this.updateUnitTrackingDetails(
      uid,
      value
        ? MonitoringStatus.ConnectionState.ON
        : MonitoringStatus.ConnectionState.OFF,
      'connection_state'
    );
  }

  checkTrackingUnitMotionState(uid: any) {
    let data = this._getUnitDataPrams(uid, 'motion_state');

    // if no lastUpdate -> no messages
    // lastActiveInHours <=1 && speed > 0 && acc -> movingOn
    // lastActiveInHours <=1 && speed <= 0 && acc -> stationaryOn
    // lastActiveInHours <=1 && speed > 0 && !acc -> movingOff
    // lastActiveInHours <=1 && speed <= 0 && !acc -> stationaryOff
    // lastActiveInHours > 1 && speed > 0 -> movingFromTime
    // lastActiveInHours > 1 && speed < 0 -> stationaryFromTime

    let timeDifferenceInHours = AfaqyHelper.getUserCurrentTime()?.diff(
      data.lastUpdate.dtt,
      'hours',
      true
    );
    let motionStatusValue: number;

    if (!data['lastUpdate']?.valid) {
      motionStatusValue = MonitoringStatus.MotionState.NoMessages;
    } else {
      if (timeDifferenceInHours <= 1) {
        if (data['lastUpdate']['spd'] > 0 && data['lastUpdate']['acc']) {
          motionStatusValue = MonitoringStatus.MotionState.MovingON;
        }
        if (data['lastUpdate']['spd'] <= 0 && data['lastUpdate']['acc']) {
          motionStatusValue = MonitoringStatus.MotionState.StationaryON;
        }
        if (data['lastUpdate']['spd'] > 0 && !data['lastUpdate']['acc']) {
          motionStatusValue = MonitoringStatus.MotionState.MovingOFF;
        }
        if (data['lastUpdate']['spd'] <= 0 && !data['lastUpdate']['acc']) {
          motionStatusValue = MonitoringStatus.MotionState.StationaryOFF;
        }
      } else {
        if (data['lastUpdate']['spd'] > 0) {
          motionStatusValue = MonitoringStatus.MotionState.MovingFromTime;
        } else {
          motionStatusValue = MonitoringStatus.MotionState.StationaryFromTime;
        }
      }
    }

    this.updateUnitTrackingDetails(uid, motionStatusValue, 'motion_state');
  }

  checkTrackingUnitDataAccuracy(uid: any) {
    let data = this._getUnitDataPrams(uid, 'motion_state');
    let satellites = MonitoringStatus.DataAccuracySatellites.NotAvailable;
    if (data.lastUpdate.valid && data.lastUpdate.prms['sat']) {
      satellites = MonitoringStatus.DataAccuracySatellites.Available;
    }
    this.updateUnitTrackingDetails(uid, satellites, 'data_accuracy_sats');

    let lastMessage = MonitoringStatus.DataAccuracyLastMessage.NoOver;
    let dttsats = 0;
    if (data.lastUpdate.valid) {
      dttsats = data.lastUpdate.dts + data.lastUpdate.prms['sat'];
      let periodInMinutes = AfaqyHelper.getUserCurrentTime()?.diff(
        data.lastUpdate.dtt,
        'minutes',
        true
      );
      if (periodInMinutes > 0 && periodInMinutes <= 5) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.FiveMinutes;
      } else if (periodInMinutes <= 60) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.HOUR;
      } else if (periodInMinutes <= 24 * 60) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.DAY;
      } else if (periodInMinutes > 24 * 60) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.LONGTIME;
      }
    }
    this.updateUnitTrackingDetails(uid, dttsats, 'data_accuracy_sorting');
    this.updateUnitTrackingDetails(
      uid,
      lastMessage,
      'data_accuracy_last_message'
    );
  }

  getTrackingUnitDataAccuracy(uid: any) {
    const data = this._getUnitDataPrams(uid, 'motion_state');
    let satellites = MonitoringStatus.DataAccuracySatellites.NotAvailable;
    if (data.lastUpdate.valid && data.lastUpdate.prms['sat']) {
      satellites = MonitoringStatus.DataAccuracySatellites.Available;
    }
    this.updateUnitTrackingDetails(uid, satellites, 'data_accuracy_sats');

    let lastMessage = MonitoringStatus.DataAccuracyLastMessage.NoOver;
    let dttsats = 0;
    if (data.lastUpdate.valid) {
      dttsats = data.lastUpdate.dts + data.lastUpdate.prms['sat'];
      const periodInMinutes = AfaqyHelper.getUserCurrentTime()?.diff(
        data.lastUpdate.dtt,
        'minutes',
        true
      );
      if (periodInMinutes > 0 && periodInMinutes <= 5) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.FiveMinutes;
      } else if (periodInMinutes <= 60) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.HOUR;
      } else if (periodInMinutes <= 24 * 60) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.DAY;
      } else if (periodInMinutes > 24 * 60) {
        lastMessage = MonitoringStatus.DataAccuracyLastMessage.LONGTIME;
      }
    }
    return lastMessage;
  }

  updateUnitTrackingDetailsFromPreferences(
    uid: any,
    field: any,
    preferencesKey: any
  ) {
    let items = this.getUserPreferences('monitoring', preferencesKey);
    let index = items.findIndex((item) => {
      return item == uid;
    });
    if (index != -1) {
      this.updateUnitTrackingDetails(uid, true, field);
    }
  }

  checkTrackingUnitDetails(uid: any, details: any) {
    for (let d of details) {
      let items = this.getUserPreferences('monitoring', d.key);
      let index = items.findIndex((item) => {
        return item == uid;
      });
      if (index != -1) {
        this.updateUnitTrackingDetails(uid, true, d.field);
      }
    }
  }

  updateUnitTrackingKeyValue(id: any, value: any, key: any) {
    this.resourceTrackingDetails[id][key] = value;
  }

  pushTracking(event: any = {}) {
    //Logger.log(event);
    this._trackingObserve.next(event);
  }

  addTotrackedResources(ids: any) {
    let newIDs = this.trackedResources;
    ids.forEach((uid: any) => {
      if (newIDs.findIndex((i) => i == uid) == -1) {
        newIDs.push(uid);
      }
    });
    this.trackedResources = newIDs;
  }

  removeTrackedItem(uid: any) {
    const objIndex = this.trackedResources.findIndex((id) => uid == id);
    if (objIndex != -1) {
      let tr = this.trackedResources;
      tr.splice(objIndex, 1);
      this.trackedResources = tr;
    }
  }

  setTrackedResourcesToALL() {
    this.trackedResources = this.AllUnitsIDs;
  }

  updateUnitTailers(updates: any) {
    if (updates['source'] == 'units') {
      if (updates['action'] == 'list') {
        for (const unit of updates['data']) {
          unit.tailer = this.tailerService.getItemFromResources(unit.tailer_id);
          this.setResourceObject(unit);
        }
      } else if (updates['action'] == 'update' || updates['action'] == 'add') {
        updates['data'].tailer = this.tailerService.getItemFromResources(
          updates['data'].tailer_id
        );
        this.setResourceObject(updates['data']);
      }
    } else {
      if (updates['action'] == 'list') {
        for (const unit of this.resourcesList) {
          for (const tailer of updates['data']) {
            if (tailer.id == unit.tailer_id) {
              unit.tailer = this.tailerService.getItemFromResources(
                unit.tailer_id
              );
              this.setResourceObject(unit);
            }
          }
        }
      } else if (updates['action'] == 'update') {
        for (const unit of this.resourcesList) {
          if (updates['data'].id == unit.tailer_id) {
            unit.tailer = this.tailerService.getItemFromResources(
              unit.tailer_id
            );
            this.setResourceObject(unit);
          }
        }
      } else if (updates['action'] == 'remove') {
        for (const unit of this.resourcesList) {
          if (updates['data'] == unit.tailer_id) {
            unit.tailer = this.tailerService.getItemFromResources(
              unit.tailer_id
            );
            this.setResourceObject(unit);
          }
        }
      }
      this.pushChanges({
        action: 'update',
        relations: true,
        data: updates['data'],
      });
    }
  }

  updateUnitStatus(updates: any) {
    if (updates['source'] == 'units') {
      if (updates['action'] == 'list') {
        for (const unit of updates['data']) {
          unit.status = this.statusService.getItemFromResources(unit.status_id);
          this.setResourceObject(unit);
        }
      } else if (updates['action'] == 'update' || updates['action'] == 'add') {
        updates['data'].status = this.statusService.getItemFromResources(
          updates['data'].status_id
        );
        this.setResourceObject(updates['data']);
      }
    } else {
      if (updates['action'] == 'list') {
        for (const unit of this.resourcesList) {
          for (const status of updates['data']) {
            if (status.id == unit.status_id) {
              unit.status = this.statusService.getItemFromResources(
                unit.status_id
              );
              this.setResourceObject(unit);
            }
          }
        }
      } else if (updates['action'] == 'update') {
        for (const unit of this.resourcesList) {
          if (updates['data'].id == unit.status_id) {
            unit.status = this.statusService.getItemFromResources(
              unit.status_id
            );
            this.setResourceObject(unit);
          }
        }
      } else if (updates['action'] == 'remove') {
        for (const unit of this.resourcesList) {
          if (updates['data'] == unit.status_id) {
            unit.status = this.statusService.getItemFromResources(
              unit.status_id
            );
            this.setResourceObject(unit);
          }
        }
      }
      this.pushChanges({
        action: 'update',
        relations: true,
        data: updates['data'],
      });
    }
  }

  updateUnitDrivers(updates: any) {
    if (updates['source'] == 'units') {
      if (updates['action'] == 'list') {
        for (const unit of updates['data']) {
          unit.driver = this.driverService.getItemFromResources(unit.driver_id);
          this.setResourceObject(unit);
        }
      } else if (updates['action'] == 'update' || updates['action'] == 'add') {
        updates['data'].driver = this.driverService.getItemFromResources(
          updates['data'].driver_id
        );
        this.setResourceObject(updates['data']);
      }
    } else {
      if (updates['action'] == 'list') {
        for (const unit of this.resourcesList) {
          for (const driver of updates['data']) {
            if (driver.id == unit.driver_id) {
              unit.driver = this.driverService.getItemFromResources(
                unit.driver_id
              );
              this.setResourceObject(unit);
            }
          }
        }
      } else if (updates['action'] == 'update') {
        for (const unit of this.resourcesList) {
          if (updates['data'].id == unit.driver_id) {
            unit.driver = this.driverService.getItemFromResources(
              unit.driver_id
            );
            this.setResourceObject(unit);
          }
        }
      } else if (updates['action'] == 'remove') {
        for (const unit of this.resourcesList) {
          if (updates['data'] == unit.driver_id) {
            unit.driver = this.driverService.getItemFromResources(
              unit.driver_id
            );
            this.setResourceObject(unit);
          }
        }
      }
      this.pushChanges({
        action: 'update',
        relations: true,
        data: updates['data'],
      });
    }
  }

  updateObjectsRelations(updates: any) {
    updates['source'] = 'units';
    this.updateUnitDrivers(updates);
    this.updateUnitTailers(updates);
    this.updateUnitStatus(updates);
  }

  /**
   * Updates custom fields based on changes between two objects.
   *
   * This function compares the `custom_fields` arrays of two objects (`oldObject` and `newObject`)
   * and identifies removed and added custom field IDs. It then updates the `unitIds` field of
   * the corresponding custom fields accordingly.
   * @param oldObject
   * @param newObject
   */
  updateCustomFields(oldObject, newObject) {
    const oldCustomFields = oldObject?.custom_fields?.map((x) => x.id);
    const newCustomFields = newObject?.custom_fields?.map((x) => x.id);
    const { removedItems, addedItems } = AfaqyHelper.compareArrays(
      oldCustomFields,
      newCustomFields
    );
    removedItems.forEach((id) => {
      let customField = this.customFieldsService.getItemFromResources(id);
      customField.unitIds = customField?.unitIds.filter(
        (x) => x !== newObject.id
      );
      this.customFieldsService.updateResourceField(
        id,
        'unitIds',
        customField.unitIds
      );
    });
    addedItems?.forEach((id) => {
      const customField = this.customFieldsService.getItemFromResources(id);
      customField.unitIds.push(newObject.id);
      this.customFieldsService.updateResourceField(
        id,
        'unitIds',
        customField.unitIds
      );
    });
  }

  updateDrivers(unit_id: any, driver_id: any) {
    for (const unit of this.resourcesList) {
      if (unit_id !== unit.id && unit.driver_id == driver_id) {
        unit.driver_id = '';
        unit.driver = new Driver();
        super.setResourceObject(unit);
      }
    }
    this.pushChanges({ action: 'updateDrivers' });
  }

  updateDriversFromSocket(unit_id: any, driver_id: any) {
    for (const unit of this.resourcesList) {
      if (unit_id !== unit.id && unit.driver_id == driver_id) {
        unit.driver_id = '';
        unit.driver = new Driver();
        super.setResourceObject(unit);
      }
    }
    this.unitDriverBinding$.next({
      relations: true,
      action: 'updateDrivers',
      unitId: unit_id,
    });
  }

  updateTailers(unit_id: any, tailer_id: any) {
    for (const unit of this.resourcesList) {
      if (unit_id !== unit.id && unit.tailer_id == tailer_id) {
        unit.tailer_id = '';
        unit.tailer = new Tailer();
        super.setResourceObject(unit);
      }
    }
    this.pushChanges({ action: 'updateTailers' });
  }

  getItems() {
    let items = [];
    for (const unit of this.resourcesList) {
      let i = {};
      for (let key of [
        'id',
        'name',
        'imei',
        'sim_number',
        'sim_serial',
        'device_serial',
        'device',
        'iconUrl',
      ]) {
        i[key] = unit[key];
      }
      i['selected'] = false;
      items.push(i);
    }
    return items;
  }

  routerPrefix(val: string = '') {
    return val ? val : 'units';
  }

  getList() {
    let glist = [];
    for (const g of this.resourcesList) {
      if (!g.parent_id) {
        glist.push({ id: g.id, name: g.name });
      }
    }
    return glist;
  }

  applYFilter(item: Unit, searchKey: any, searchText: any) {
    if (searchText == '' || typeof searchText === 'undefined') {
      return true;
    }
    let result = false;
    searchText = searchText.toLowerCase();
    if (searchKey == 'all') {
      const keys = this.getSearchKeys().map((i) => {
        return i.value;
      });
      keys.forEach((key) => {
        if (key != 'all') {
          if (key == 'groupsList') {
            result = this.searchUnitByGroupName(item, searchText)
              ? true
              : result;
          } else if (key == 'model' || key == 'vin' || key == 'plate_number') {
            result = this.searchUnitByProfile(item, key, searchText)
              ? true
              : result;
          } else if (
            typeof item[key] !== 'undefined' &&
            this.validateSearchTextExistInItem(item[key], searchText)
          ) {
            result = true;
          }
        }
      });
    } else {
      if (searchKey == 'groupsList') {
        result = this.searchUnitByGroupName(item, searchText);
      } else if (
        searchKey == 'model' ||
        searchKey == 'vin' ||
        searchKey == 'plate_number'
      ) {
        result = this.searchUnitByProfile(item, searchKey, searchText);
      } else {
        result =
          typeof item[searchKey] != 'undefined'
            ? this.validateSearchTextExistInItem(item[searchKey], searchText)
            : false;
      }
    }
    return result;
  }

  searchUnitByGroupName(item: any, searchText: any) {
    const groupIds = this.unitGroupService.resourcesList
      .filter((group) =>
        this.validateSearchTextExistInItem(group['name'] + '', searchText)
      )
      .map((group) => group.id);
    let status = false;
    if (groupIds.length > 0) {
      groupIds.forEach((gID) => {
        if (item['groupsList'].includes(gID)) {
          status = true;
        }
      });
    }
    return status;
  }

  searchUnitByProfile(item: any, searchKey: any, searchText: any) {
    return typeof item['profile'][searchKey] != 'undefined'
      ? this.validateSearchTextExistInItem(
          item['profile'][searchKey] + '',
          searchText
        )
      : false;
  }

  getSearchKeys() {
    let returnSearchKeys = [
      { value: 'name', header: 'name' },
      { value: 'creator', header: 'creator' },
      { value: 'owner', header: 'owner' },
      { value: 'imei', header: 'imei' },
      { value: 'device', header: 'device' },
      { value: 'sim_number', header: 'sim_number' },
      { value: 'sim_serial', header: 'sim_serial' },
      { value: 'device_serial', header: 'device_serial' },
      { value: 'device', header: 'protocol' },
      { value: 'model', header: 'model' },
      { value: 'vin', header: 'vin' },
      { value: 'plate_number', header: 'plat number' },
    ];
    if (this.authService.checkPermissions('drivers-lists')) {
      returnSearchKeys.push({ value: 'driverName', header: 'driver' });
    }
    if (this.authService.checkPermissions('tailers-lists')) {
      returnSearchKeys.push({ value: 'tailerName', header: 'trailer' });
    }
    if (this.authService.checkPermissions('unit_groups-lists')) {
      returnSearchKeys.push({ value: 'groupsList', header: 'group' });
    }
    returnSearchKeys.push({ value: 'all', header: 'all' });
    return returnSearchKeys;
  }

  groupedList(deletedGroupIds: any[] = []) {
    let uList = [];
    this.trackingObjects.forEach((item) => {
      if (item.groupsList.length) {
        item.groupsList.forEach((groupid) => {
          if (deletedGroupIds.findIndex((id) => id == groupid) == -1) {
            let newitem = item.clone();
            newitem['group_id'] = groupid;
            newitem['group_name'] =
              this.unitGroupService.getItemFromResources(groupid).name;
            uList.push(newitem);
          }
        });
      } else if (deletedGroupIds.findIndex((id) => id == '0') == -1) {
        let newitem = item.clone();
        newitem['group_id'] = 0;
        newitem['group_name'] = 'Un-grouped';
        uList.push(newitem);
      }
    });
    return uList;
  }

  updateFollowBox(unitID: any) {
    if (!unitID) {
      return;
    }
    let ids = AfaqyHelper.cloneObject(
      this.getUserPreferences('monitoring', 'followBoxes', [])
    );
    if (!ids.includes(unitID)) {
      ids.push(unitID);
    } else {
      ids = ids.filter((id: any) => {
        return unitID != id;
      });
    }
    this.updateUserPreferences('monitoring', 'followBoxes', ids);
    this.pushTracking({ event: 'followBoxes' });
  }

  updateTrackerData(unitIdentifier: any, data: any) {
    let unit = <Unit>this.getItemFromResources(unitIdentifier);
    if (!unit.id) {
      return;
    }
    let lastUpdate = unit.last_update || new UnitLastUpdate();
    //'ip', 'rl', 'trl', 'mdu', 'tmdu', 'eidl', 'teidl', 'fc', 'tfc', 'accOn', 'accOff', 'sdu', 'tsdu']
    if (data && data.d.length > 0) {
      const updateParams = data['d'];
      lastUpdate.vCon = data['cv'];
      lastUpdate.vLoc = data['lv'];
      lastUpdate.dts = parseInt(moment(updateParams[0]).format('x'));
      lastUpdate.dtt = parseInt(moment(updateParams[1]).format('x'));

      lastUpdate.lng = updateParams[3];
      lastUpdate.lat = updateParams[2];

      // console.log('updateTrackerData', lastUpdate.lat , lastUpdate.lng);
      if (lastUpdate.lat && lastUpdate.lng) {
        // console.log(
        //   '%cSetting last_known_location',
        //   'color: red; font-weight: bold; background: black;'
        // );
        unit.last_update.lastloc = {
          lat: lastUpdate.lat,
          lng: lastUpdate.lng,
        };
      }

      lastUpdate.alt = updateParams[4];
      lastUpdate.ang = updateParams[5];
      lastUpdate.spd = updateParams[6];
      lastUpdate.prms = updateParams[7];
      lastUpdate.acc = updateParams[8];
      lastUpdate.chPrams = updateParams[10];
      if (lastUpdate.lng && lastUpdate.lat) {
        let newpoint = [lastUpdate.lng, lastUpdate.lat];
        if (lastUpdate.tail.length == 0) {
          lastUpdate.tail.push(newpoint);
        } else {
          const lastPoint = lastUpdate.tail[lastUpdate.tail.length - 1];
          if (!(lastPoint[0] == newpoint[0] && lastPoint[1] == newpoint[1])) {
            lastUpdate.tail.push(newpoint);
          }
        }
      }
      if (lastUpdate.tail.length > 4) {
        lastUpdate.tail.shift();
      }
      lastUpdate.status = 'online';

      lastUpdate.addr = data['addr'];
    }
    unit.counters.odometer = data['o'];
    unit.counters.engine_hours = data['eh'];
    unit.last_update = lastUpdate;
    this.setResourceObject(unit);

    return unit;
  }

  updateMonitoredObjects() {
    if (this.trackALLUnits || (this.pagination && this.pagination.hasNext())) {
      return;
    }
    let removeFromTracking = [];
    this.trackedResources.forEach((uid) => {
      //Connection State
      this.checkTrackingUnitConnectionState(uid);
      //Motion State
      this.checkTrackingUnitMotionState(uid);

      let data = this._getUnitDataPrams(uid, 'data_accuracy');
      const duration = data.duration;
      const filteration = data['params']['filteration'] || '';
      if (filteration && duration > 0) {
        let period = AfaqyHelper.getUserCurrentTime()?.diff(
          data.lastUpdate.dts,
          'minutes',
          true
        );
        if (period > duration) {
          if (filteration == 'monitoring_map') {
            removeFromTracking.push(uid);
          } else if (filteration == 'map') {
            this.updateUnitTrackingDetails(uid, false, 'visible');
          }
        }
      }
    });

    if (removeFromTracking.length) {
      var filteredTrackedIDs = this.trackedResources.filter(
        (uid) => removeFromTracking.indexOf(uid) == -1
      );
      this.trackedResources = filteredTrackedIDs;
      this.showALLUnits(false);
    }
    this.pushTracking({ event: 'visible', checked: true });
  }

  updateObjectStatus(data: any) {
    let unit = <Unit>this.getItemFromResources(data['unitId']);
    if (!unit.id) {
      return;
    }
    let lastUpdate = unit.last_update || new UnitLastUpdate();
    lastUpdate.status = data['status'];
    unit.last_update = lastUpdate;
    super.setResourceObject(unit);
    this.checkTrackingUnitConnectionState(unit.id);
    this.pushTracking({ refresh: false, ids: [unit.id] });

    this.bookLog.log({
      resource: unit.name,
      type: 'status',
      message: data['status'],
      dir: 2,
      date: data['date'] / 1000,
      loc: { lat: unit.last_update.lat, lng: unit.last_update.lng },
    });
  }

  updateObjectsBinding(data: any) {
    let unit = <Unit>this.getItemFromResources(data['unitId']);
    if (!unit.id) {
      return;
    }

    if (data['type'] == 'bind_driver') {
      unit.driver_id = '';
      if (data['unitId'] != data['id']) {
        unit.driver_id = data['id'];
      }

      unit.driver = this.driverService.getItemFromResources(data['id']);
      this.updateDriversFromSocket(data['unitId'], data['id']);
    }
    if (data['type'] == 'bind_trailer') {
      unit.tailer_id = data['id'];
      unit.tailer = this.tailerService.getItemFromResources(data['id']);
      this.updateTailers(data['unitId'], data['id']);
    }

    this.bookLog.log({
      resource: unit.name,
      type: data['type'],
      message:
        data['type'] == 'bind_driver'
          ? 'Bind Driver => ' + unit.driverName
          : 'Bind Trailer => ' + unit.tailerName,
      dir: 2,
      date: data['date'] / 1000,
      loc: { lat: unit.last_update.lat, lng: unit.last_update.lng },
    });
  }

  updateObjectsTracking(data: any) {
    let mapIds = [];
    let listIds = [];
    let followBoxes = this.getUserPreferences('monitoring', 'followBoxes', []);
    let logs = [];
    for (let imei in data) {
      let unit = this.updateTrackerData(imei, data[imei]);
      if (!unit) {
        continue;
      }
      logs.push({
        resource: unit.name,
        type: 'update',
        message: 'location',
        dir: 2,
        date: unit.last_update.dts / 1000,
        loc: { lat: unit.last_update.lat, lng: unit.last_update.lng },
      });
      listIds.push(unit.id);
      if (
        unit.last_update.lng &&
        unit.last_update.lat &&
        (this.resourceTrackingDetails[unit.id]['visible'] ||
          followBoxes.indexOf(unit.id) > -1)
      ) {
        mapIds.push(unit.id);
      }
    }
    if (logs.length) {
      this.bookLog.logBulk(logs);
    }
    this.updateStats();
    if (listIds.length) {
      this.pushTracking({
        refresh: false,
        event: 'monitoring_location_update',
        ids: listIds,
      });
      this.updatedListIds.next(listIds);
    }
    if (mapIds.length) {
      this.pushTracking({
        refresh: false,
        event: 'location_update',
        ids: mapIds,
      });
    }
  }

  updateUnitNearest(uid: any, type: any) {
    let lastUpdate = this.getUnitLastUpdate(uid);
    if (lastUpdate) {
      let value =
        type == 'nearest_zone'
          ? this.zoneService.getNearestZone(lastUpdate.lat, lastUpdate.lng)
          : this.markerService.getNearestMarker(lastUpdate.lat, lastUpdate.lng);
      this.updateUnitTrackingDetails(uid, value, type);
    }
  }

  doGetAddress(coordinates: any) {
    if (coordinates?.lat === 0 && coordinates?.lng === 0) {
      return of(coordinates?.lng + ', ' + coordinates?.lat);
    } else {
      return this.apiRequest
        .authPost(this.getFunctionURL('getAddress'), coordinates)
        .pipe(
          map((result: AfaqyAPIResponse) => {
            if (result.status_code == 200) {
              if (result.data.address && result.data.address != '') {
                return result.data.address;
              }
              return coordinates?.lng + ', ' + coordinates?.lat;
            }
            return '';
          })
        );
    }
  }

  getAddressFromLastUpdate(uid: any) {
    const lu = this.getUnitLastUpdate(uid);
    const lang = this.authService.transLang;
    if (lu?.addr && lu?.addr[lang]) {
      return lu?.addr[lang];
    }

    return false;
  }

  getAddress(uid: any, coordinates: any) {
    const address = this.getAddressFromLastUpdate(uid);
    if (address) {
      this.updateUnitTrackingDetails(uid, address, 'address');
    } else {
      let lastCoordinates = this.resourceTrackingDetails[uid]?.['last_cords'];
      if (
        coordinates?.['lng'] !== lastCoordinates?.['lng'] ||
        coordinates?.['lat'] !== lastCoordinates?.['lat']
      ) {
        this.updateUnitTrackingDetails(uid, '', 'address');
        this.doGetAddress(coordinates).subscribe((address) => {
          this.updateUnitTrackingDetails(uid, address, 'address');
          this.updateUnitTrackingDetails(
            uid,
            AfaqyHelper.cloneObject(coordinates),
            'last_cords'
          );
        });
        this.updateUnitTrackingDetails(uid, null, 'lastKnownAddress');
      }
    }

    const unit = this.getUnitDetails(uid);
    const lastKnownCoordinates = {
      lat: unit?.last_update?.lastloc.lat,
      lng: unit?.last_update?.lastloc.lng,
    };

    if (coordinates?.lat === 0 && coordinates?.lng === 0) {
      if (lastKnownCoordinates.lat && lastKnownCoordinates.lng) {
        this.updateUnitTrackingDetails(uid, false, 'validLatLng');
        this.updateUnitTrackingDetails(uid, null, 'lastKnownAddress');
        this.doGetAddress(lastKnownCoordinates).subscribe(
          (lastKnownAddress) => {
            this.updateUnitTrackingDetails(
              uid,
              lastKnownAddress,
              'lastKnownAddress'
            );
          }
        );
      }
    } else {
      this.updateUnitTrackingDetails(uid, true, 'validLatLng');
      this.updateUnitTrackingDetails(uid, null, 'lastKnownAddress');
    }
  }

  getMultipleAddresses(locationsList: any) {
    return this.apiRequest
      .authPost(this.getFunctionURL('getMultipleAddresses', 'units'), {
        points: locationsList,
      })
      .pipe(
        map((result: AfaqyAPIResponse) => {
          if (result.status_code == 200) {
            return result.data;
          }
          return [];
        })
      );
  }

  getCustomFieldsList() {
    if (this.customFieldsList) {
      return of(this.customFieldsList);
    }
    return this.apiRequest.authPost('custom_fields/lists', {}).pipe(
      map((result: AfaqyAPIResponse) => {
        if (result.status_code == 200) {
          this.customFieldsList = result?.data
            .filter((customField: any) => {
              return customField['is_used_in_units'];
            })
            .map((customField: any) => customField.key);
          return this.customFieldsList;
        }
        return [];
      }),
      catchError((err) => this.serverError(err))
    );
  }

  gridColumns(trashed: boolean = false) {
    let allcols: any[] = [
      {
        header: 'multiple_select',
        minWidth: 28,
        width: 30,
        colValue: 'checkbox',
        active: true,
        immutable: false,
        default: true,
        type: 'checkbox',
        sorting: false,
        filters: false,
        disableKey: 'isViewOnly',
      },
      {
        header: 'icon',
        minWidth: 40,
        width: 40,
        colValue: 'iconUrl',
        filters: false,
        active: false,
        immutable: false,
        default: true,
        type: 'icon',
      },
      {
        header: 'name',
        minWidth: 100,
        width: '*',
        colValue: 'name',
        active: false,
        immutable: true,
        default: true,
      },
      {
        header: 'creator',
        minWidth: 100,
        width: '*',
        colValue: 'creator',
        active: false,
        default: false,
      },
      {
        header: 'owner',
        minWidth: 100,
        width: '*',
        colValue: 'owner',
        active: false,
        default: false,
      },
    ];
    if (this.authService.checkPermissions('units-deviceAdmin')) {
      const newcols = [
        {
          header: 'imei',
          minWidth: 75,
          width: '*',
          colValue: 'imei',
          active: false,
          default: true,
        },
        {
          header: 'sim_number',
          minWidth: 110,
          width: '*',
          colValue: 'sim_number',
          active: false,
          default: true,
        },
        {
          header: 'sim_serial',
          minWidth: 100,
          width: '*',
          colValue: 'sim_serial',
          active: false,
          default: false,
        },
        {
          header: 'device_serial',
          minWidth: 100,
          width: '*',
          colValue: 'device_serial',
          active: false,
          default: false,
        },
      ];
      allcols = allcols.concat(newcols);
    }
    if (this.authService.checkPermissions('units-DisplayWaslInfo')) {
      const newcols = [
        {
          header: 'is_wasl_connected',
          minWidth: 100,
          width: '*',
          colValue: 'is_wasl_connected',
          active: false,
          default: false,
        },
      ];
      allcols = allcols.concat(newcols);
    }
    if (!AppConfig.isCMS) {
      allcols.push({
        header: 'driver',
        minWidth: 100,
        width: '*',
        colValue: 'driverName',
        active: false,
        default: false,
      });
      allcols.push({
        header: 'tailer',
        minWidth: 100,
        width: '*',
        colValue: 'tailerName',
        active: false,
        default: false,
      });
    }
    allcols.push({
      header: 'actions',
      minWidth: 60,
      width: '*',
      colValue: 'actions',
      active: false,
      default: true,
    });
    const lastInx = allcols.length - 1;
    allcols[lastInx]['width'] = 100;
    if (!trashed) {
      allcols[lastInx]['extra'] = [
        {
          header: 'assign_user',
          permissions: this.cid + '-assign',
          code: 'assign',
          faclass: 'fa-link',
        },
        {
          header: 'unassign_user',
          permissions: this.cid + '-unassign',
          code: 'unassign',
          faclass: 'fa-unlink',
        },
        {
          header: this.cid + '.bind_driver',
          permissions: 'drivers-setUnitDriver',
          code: 'bindDriver',
          faclass: 'fa-user',
        },
        {
          header: this.cid + '.bind_tailer',
          permissions: 'tailers-setUnitTailer',
          code: 'bindTailer',
          faclass: 'fa-train',
        },
        {
          header: this.cid + '.set_status',
          permissions: 'units-set_status',
          code: 'set_status',
          faclass: 'fa-tag',
        },
      ];
      if (!this.authService.checkPermissions(this.cid + '-bulkDelete')) {
        allcols.shift();
      }
    } else {
      allcols.shift();
    }
    return allcols;
  }

  private _getUnitDataPrams(uid: any, key: any) {
    const unitDetails = this.getUnitDetails(uid);
    const userPref = this.getUserPreferences('monitoring', 'gridCols');
    const lastUpdate = unitDetails
      ? unitDetails.last_update
      : new UnitLastUpdate();
    const duration =
      parseInt(userPref[key] && userPref[key].params.duration) || 0;
    const params = userPref[key]?.params;
    return { lastUpdate: lastUpdate, duration: duration, params: params };
  }

  private _checkWithDuration(duration: any, dtt: any) {
    const period = AfaqyHelper.getUserCurrentTime()?.diff(dtt, 'minutes', true);
    return period >= duration ? 0 : 1;
  }

  public calcServiceStatus(service: UnitServiceItem, odo: any, enh: any) {
    let output = [];
    let status = false;

    // service types
    const odometer = service.odometer;
    const engine = service.engine_hours;
    const days = service.days;
    const dates = service.date;

    // check if the types is activated
    if (odometer.status) {
      const text = this.validateOdo(odo, odometer);
      output.push(this.statusSubElementDecorator(text));

      if (text['status'] === 1) {
        status = true;
      }
    }

    if (engine.status) {
      const text = this.validateEngineHours(enh, engine);
      output.push(this.statusSubElementDecorator(text));

      if (text['status'] === 1) {
        status = true;
      }
    }

    if (days.status) {
      const text = this.validateDays(days);
      output.push(this.statusSubElementDecorator(text));

      if (text['status'] === 1) {
        status = true;
      }
    }

    if (dates.status) {
      const text = this.validateDate(dates);
      output.push(this.statusSubElementDecorator(text));

      if (text['status'] === 1) {
        status = true;
      }
    }

    return { expired: status, text: output.join(', ') };
  }

  private validateOdo(actual: any, blueprint: any) {
    const current_state = actual - blueprint.last;

    let abs = Math.abs(current_state - blueprint.interval);

    let paresdAbs = parseFloat('' + abs).toFixed(2);

    let returned = {};

    if (current_state >= blueprint.interval) {
      returned['text'] = this.translateService.instant(
        'units.unit_services.odo_exceeded',
        { count: paresdAbs }
      );
      returned['status'] = 1;
    } else {
      returned['text'] = this.translateService.instant(
        'units.unit_services.odo_remaining',
        { count: paresdAbs }
      );
      returned['status'] = -1;
    }

    return returned;
  }

  /**
   * Calculate Service Status for engine hours type
   *
   * @param {number} actual
   * @param {ServiceType} blueprint
   * @returns {{}}
   */
  private validateEngineHours(actual: number, blueprint: ServiceType): any {
    actual = actual / 3600;

    let parsedActual: any = parseFloat('' + actual).toFixed(2);

    const currentState = parsedActual - blueprint.last;

    let abs = Math.abs(currentState - blueprint.interval);

    let returned = {};

    if (currentState >= blueprint.interval) {
      returned['text'] = this.translateService.instant(
        'units.unit_services.engine_exceeded',
        { count: abs }
      );
      returned['status'] = 1;
    } else {
      returned['text'] = this.translateService.instant(
        'units.unit_services.engine_remaining',
        { count: abs }
      );
      returned['status'] = -1;
    }
    return returned;
  }

  /**
   * Calculate Service Status for days type
   *
   * @param {ServiceType} blueprint
   * @returns {{}}
   */
  private validateDays(blueprint: ServiceType): any {
    /**
     * The current day
     *
     * @type {Date}
     */
    const today = new Date();

    /**
     * Last date service was updated
     *
     * @type {Date}
     */
    const lastDay: any = new Date(blueprint.last);

    /**
     * calculates days margin
     *
     * @type {number}
     */
    const diffBetweenTodayAndServiceDate = this.dateDiffInDays(lastDay, today);

    /**
     * Exceeded days count
     *
     * @type {number}
     */
    const exceeded: any = diffBetweenTodayAndServiceDate - blueprint.interval;
    // returned value, bag
    let serviceCurrentState = {};

    // greater than deadline
    if (diffBetweenTodayAndServiceDate >= blueprint.interval) {
      serviceCurrentState['text'] = this.translateService.instant(
        'units.unit_services.day_exceeded',
        { exceeded: exceeded }
      );
      serviceCurrentState['status'] = 1;

      // still there is time
    } else {
      serviceCurrentState['text'] = this.translateService.instant(
        'units.unit_services.day_remaining',
        { count: Math.abs(exceeded) }
      );
      serviceCurrentState['status'] = -1;
    }
    return serviceCurrentState;
  }

  /**
   * Calculates the margin between to dates
   *
   * @param {Date} date1
   * @param {Date} date2
   * @returns {number} days
   */
  private dateDiffInDays = function (date1: Date, date2: Date): any {
    return Math.floor(
      (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
        Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  };

  /**
   * Calculate Service Status for date type
   * @param {ServiceType} blueprint
   * @returns {{}}
   */
  private validateDate(blueprint: ServiceType): any {
    /**
     * The current day
     *
     * @type {Date}`
     */
    const today = new Date();

    /**
     * The specified service date
     *
     * @type {Date}
     */
    const expected: Date = new Date(blueprint.date);

    /**
     * calculates days margin
     *
     * @type {number}
     */
    const diffBetweenTodayAndServiceDate = this.dateDiffInDays(today, expected);

    // returned value, bag
    let serviceCurrentState = {};

    // it's time, what you are waiting for?!
    if (diffBetweenTodayAndServiceDate === 0) {
      serviceCurrentState['text'] = this.translateService.instant(
        'units.unit_services.date_today'
      );
      serviceCurrentState['status'] = 1;
    }

    // ok, There is a period of time
    if (diffBetweenTodayAndServiceDate > 0) {
      serviceCurrentState['text'] = this.translateService.instant(
        'units.unit_services.date_remaining',
        { count: diffBetweenTodayAndServiceDate }
      );
      serviceCurrentState['status'] = -1;
    }

    // The service date has passed
    if (diffBetweenTodayAndServiceDate < 0) {
      serviceCurrentState['text'] = this.translateService.instant(
        'units.unit_services.date_exceeded',
        { margin: Math.abs(diffBetweenTodayAndServiceDate) }
      );
      serviceCurrentState['status'] = 1;
    }

    // return current state to be sent to Decorator
    return serviceCurrentState;
  }

  /**
   * generates HTML representation
   *
   * @param element
   * @returns {string}
   */
  private statusSubElementDecorator(element: any): any {
    const style = element['status'] == 1 ? 'danger' : 'success';
    return `<span class="${style}">${element['text']}</span>`;
  }

  public getStatusUnitCount(sid: any) {
    if (!this.statusService.isResourceExist(sid)) {
      return 0;
    }
    let count = 0;
    this.resourcesList.forEach((item: Unit) => {
      if (item.job_order.status_id == sid) {
        count++;
      }
    });
    return count;
  }

  public getLinkedStatusUnitCount() {
    let count = 0;
    this.resourcesList.forEach((item: Unit) => {
      if (
        item.job_order.status_id &&
        this.statusService.isResourceExist(item.job_order.status_id)
      ) {
        count++;
      }
    });
    return count;
  }

  subscribeToPusherSocketNotifications() {
    this.pusherSocketService.notification.subscribe((data: any) => {
      this.processId = data?.data?.data?.logId;
      this.copyInfoData = data?.data?.data;
    });
  }

  // refactorListResponse(res) {
  //   console.log(res);
  //   res.list.map((unit: Unit) => {
  //     //   unit?.last_update?.last_known_location = {
  //     //     lat: 26.97654,
  //     //     lng: 44.6827851,
  //     //   };
  //   });
  //   return res;
  // }

  refactorListResponse(res) {
    const isBulkDeleteGranted =
      this.authService.checkPermissions('units-bulkDelete');
    // using reduce to iterate one time only for filter and mapping data
    res.list = res?.list?.reduce((filteredUnits, unit) => {
      if (!unit.imei) return filteredUnits;
      if (unit.is_shared && unit.permission) {
        if (unit.permission === 'view_only') unit.isViewOnly = true;
        else if (unit.permission === 'user_permission') {
          unit.isViewOnly = !isBulkDeleteGranted;
        }
      } else unit.isViewOnly = false;
      filteredUnits.push(unit);
      return filteredUnits;
    }, []);
    return res;
  }

  getCustomFieldsUsageCount() {
    const combinedObservable = combineLatest(
      this.finishedLoading,
      this.customFieldsService.finishedLoading
    );

    return combinedObservable.pipe(
      tap({
        next: ([unitFinishedLoading, customFieldFinishedLoading]) => {
          if (unitFinishedLoading && customFieldFinishedLoading) {
            this.resourcesList.forEach((unit: Unit) => {
              unit.custom_fields.forEach((unitCustomField: CustomField) => {
                let customFieldUnit: CustomField =
                  this.customFieldsService.getItemFromResources(
                    unitCustomField.id
                  );
                let customFieldUnitIds: string[] = customFieldUnit.unitIds;
                if (customFieldUnit?.id) {
                  customFieldUnitIds = [...[unit.id], ...customFieldUnitIds];
                  this.customFieldsService.updateResourceField(
                    customFieldUnit.id,
                    'unitIds',
                    customFieldUnitIds
                  );
                }
              });
            });
          }
        },
      })
    );
  }
}
