import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiRequestService } from '../../../../core/services';
import { UnitService } from '../../../../modules/units/services';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'unit-command-clone-form',
  templateUrl: './unit-form-command-clone-form.component.html',
  styleUrls: ['./unit-form-command-clone-form.component.scss'],
})
export class UnitFormCommandCloneFormComponent {
  public myGroup: UntypedFormGroup;
  posting: boolean;
  public unitService: UnitService;
  @Input() commandId: string;
  @Input() unitID?: any;
  alive = true;
  unitsList = [];
  @Output() closeForm: EventEmitter<any> = new EventEmitter<any>();
  cid = 'units';
  constructor(
    unitService: UnitService,
    private translate: TranslateService,
    private ApiRequestService: ApiRequestService
  ) {
    this.unitService = unitService;
    this.myGroup = new UntypedFormGroup({
      unitsList: new UntypedFormControl(),
    });
    this.filterUnits();
  }

  filterUnits() {
    this.unitService.finishedLoading
      .pipe(takeWhile(() => this.alive))
      .subscribe((finishedLoading) => {
        if (finishedLoading) {
          this.unitsList = this.unitService.resourcesList.filter((u) => {
            return u.device_type === 'gps' && u.permission !== 'view_only';
          });
        }
      });
  }

  get fields(): UntypedFormArray {
    return this.myGroup.get('unitsList') as UntypedFormArray;
  }

  get form_fields(): any {
    return {
      unitsList: [Validators.required],
    };
  }

  modalClose($event: any) {
    this.closeForm.next($event);
  }

  onSubmit() {
    const payload = {
      units: this.fields.value,
      command_id: this.commandId,
    };

    this.ApiRequestService.authPost(
      'unit_commands/clone_command',
      payload
    ).subscribe({
      next: (res) => {
        if (res['status_code'] === 200) {
          this.modalClose({
            success: true,
            type: 'success',
            msg: 'commands.command_is_cloned',
          });
        } else {
          this.unitService.alert('commands.command_is_duplicated');
        }
      },
    });
  }
}
