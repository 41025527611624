import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { WjFlexGridFilter } from '@grapecity/wijmo.angular2.grid.filter';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

import { AfaqyHelper, AppConfig } from '../../../common/classes';
import { AfaqyResponse } from '../../../core/classes/afaqy-response';
import { AuthService, RootService } from '../../../core/services';

import { Router } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { ListCustomAction } from 'app/shared/interfaces/interfaces';

@Component({
  selector: 'afaqy-flex-grid',
  exportAs: 'AfaqyGrid',
  templateUrl: './afaqy-flex-grid.component.html',
  styleUrls: ['./afaqy-flex-grid.component.scss'],
})
export class AfaqyFlexGridComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  private alive: boolean = true;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteForever: EventEmitter<any> = new EventEmitter<any>();
  @Output() restore: EventEmitter<any> = new EventEmitter<any>();
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleCheck: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateIds: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeActions: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() filteredItems: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() service: RootService;
  @Input() allColumns: any[] = [];
  @Input() controller: string = '';
  @Input() is_trashed: boolean = false;
  @Input() refresh: boolean;
  @Input() customActions: ListCustomAction[];
  isVisible: boolean = false;
  isAllChecked: boolean;

  @ViewChild('grid') grid: WjFlexGrid;
  @ViewChild('extraActionsContainer') extraActionsContainer: any;

  activeColumns: any[] = [];
  activeColumnsProp: string[] = [];
  filtersCols: any[] = [];
  showExtra = false;
  extraActions = [];
  isRTL = false;
  showFilterIcons = false;
  hasActions = false;
  extraParams = {
    permissions: '',
    hideExtra: true,
    elmTop: 0,
    elmLeft: 0,
    elmRight: 0,
    item: {},
  };
  actions = { edit: 'edit', copy: 'copy', delete: 'delete', view: 'view' };
  cvData: CollectionView;
  config = AppConfig;
  gridWidth: number;
  editPermission: boolean = false;
  moduleName: string;
  visibleAll: boolean;
  // updateGridResourcesCount = 0; // for debugging only updateGridResources execution on each module

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router
  ) {
    this.isRTL = authService.isRTLLang();
    this.translate.onLangChange.subscribe({
      next: () => {
        this.isRTL = authService.isRTLLang();
      },
    });
  }

  ngOnInit() {
    this.moduleName =
      this.router.url.split('/')[this.router.url.split('/').length - 1];
    if (this.moduleName.includes('?'))
      this.moduleName = this.moduleName.split('?')[0];
    AfaqyHelper.resizer.pipe(takeWhile(() => this.alive)).subscribe({
      next: () => {
        // console.log('resizer pushed')
        if (this.grid && this.grid.isInitialized) {
          this.grid.refresh(true);
          this.isAllChecked = false;
        }
      },
    });

    if (this.authService.checkPermissions(this.controller + '-edit', '')) {
      this.editPermission = true;
    }
    ['add', 'edit', 'view', 'delete'].forEach((action) => {
      if (
        this.authService.checkPermissions(this.controller + '-' + action, '')
      ) {
        this.hasActions = true;
      }
    });
    if (
      this.is_trashed &&
      this.authService.checkPermissions(this.controller + '-restore', '')
    ) {
      this.hasActions = true;
    }
    if (
      this.is_trashed &&
      this.authService.checkPermissions(this.controller + '-deleteForever', '')
    ) {
      this.hasActions = true;
    }
    this.updateActiveColumns(true);
    // disabled this onInit because the grid isn't Initialized yet, and added it inside AfterViewInit
    // this.grid.rowHeaders.columns.defaultSize = 25;

    this.cvData = new CollectionView([]);
    // this.updateGridResources();

    this.service.resources.pipe(takeWhile(() => this.alive)).subscribe({
      next: (event) => {
        if (this.is_trashed) {
          if (event['action'] == 'restore') {
            this.cvData.sourceCollection = this.cvData.sourceCollection.filter(
              (item) => {
                return (
                  event['data'].findIndex((id: any) => item.id == id) == -1
                );
              }
            );
          } else if (
            event['action'] == 'refresh' ||
            event['action'] == 'remove-forever'
          ) {
            this.updateGridResources();
          }
        } else if (event['action'] != 'visible') {
          this.updateGridResources();
        }

        if (this.allColumns.some((d) => d.colValue === 'visible')) {
          this.visibleAll = this.cvData.sourceCollection.every(
            (d) => d.visible
          );
        }
      },
    });
    for (let colmn of this.allColumns) {
      if (colmn.colValue != 'actions' && colmn.filters !== false) {
        this.filtersCols.push(colmn.colValue);
      }
    }
    this.flexGridWidth();

    this.service.finishedLoading.pipe(takeWhile(() => this.alive)).subscribe({
      next: (finished: boolean) => {
        this.showFilterIcons = finished;
      },
    });
    this.clearChecked();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refresh && changes.refresh.currentValue) {
      // console.log('grid refreshed called');
      this.grid.refresh();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.flexGridWidth();
  }

  clearChecked() {
    this.cvData?.items.map((item) => (item.checked = false));
  }

  flexGridWidth() {
    const dataWidth = parseFloat(
      document.body.style.getPropertyValue('--data-width')
    );
    this.gridWidth = dataWidth - 10;
  }

  updateGridResources() {
    // this.updateGridResourcesCount++;
    // console.log('this.count', this.service.cid, this.updateGridResourcesCount);

    if (!this.is_trashed) {
      if (this.service.partialLoaded) {
        this.loaded.next();
      }
      const listCountString = ' ' + this.service.resourcesList.length;
      this.setGridColumnsDefaultSize(listCountString);
      this.cvData = this._createItemsSource(this.service.resourcesList);
    } else {
      this.service
        .getTrashedList({ limit: 0 })
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (reponse: AfaqyResponse) => {
            const listCountString = ' ' + reponse.list.length;
            this.setGridColumnsDefaultSize(listCountString);
            this.cvData = this._createItemsSource(reponse.list);
            this.loaded.next();
            setTimeout(() => {
              AfaqyHelper.windowResize();
            }, 10);
          },
        });
    }
  }

  setGridColumnsDefaultSize(listCountString: any) {
    if (this.grid && this.grid.isInitialized) {
      // console.log('grid.isInitialized', this.grid);
      this.grid.rowHeaders.columns.defaultSize =
        listCountString.length * 10 > 25 ? listCountString.length * 10 : 25;
    }
  }

  updateActiveColumns(updates: boolean = false) {
    for (let key in this.allColumns) {
      if (!this.allColumns[key]['align']) {
        this.allColumns[key]['align'] = this.isRTL ? 'right' : 'left';
      } else if (this.isRTL && this.allColumns[key]['align'] != 'center') {
        this.allColumns[key]['align'] =
          this.allColumns[key]['align'] == 'left' ? 'right' : 'left';
      }
    }
    const gridColsKEY = 'gridCols' + (this.is_trashed ? '_trached' : '');
    let activeColumns = [];
    let savedDef = this.service.getUserPreferences(
      this.controller,
      gridColsKEY
    );
    for (let key in this.allColumns) {
      if (updates) {
        if (savedDef.length) {
          if (
            savedDef.findIndex((value) => {
              return this.allColumns[key].colValue == value;
            }) != -1
          ) {
            this.allColumns[key].active = true;
          }
        } else if (this.allColumns[key].default) {
          this.allColumns[key].active = true;
        }
      }
      if (this.allColumns[key].active) {
        activeColumns.push(this.allColumns[key]);
      }
    }
    this.activeColumns = activeColumns;
    this.showExtra = false;
    this.activeColumns.forEach((item) => {
      if (item.colValue == 'actions') {
        if (item.actions) {
          if (item.actions && item.actions['edit']) {
            this.actions['edit'] = item.actions['edit'];
          }
          if (item.actions && item.actions['copy']) {
            this.actions['copy'] = item.actions['copy'];
          }
        }
        if (item.extra) {
          this.showExtra = true;
          this.extraActions = item.extra;
          let permissions = [];
          item.extra.forEach((a) => {
            if (a.permissions.trim()! + '') {
              permissions.push(a.permissions);
              if (this.authService.checkPermissions(a.permissions, '')) {
                this.hasActions = true;
              }
            }
          });
          this.extraParams.permissions = permissions.join(',');
        }
      }
    });
    if (!this.hasActions) {
      this.activeColumns = this.activeColumns.filter(
        (item) => item.colValue != 'actions'
      );
      this.removeActions.next();
    }
    if (this.showExtra == false) {
      this.extraActions = [];
    }
    let options = this.activeColumns.map(function (item) {
      return item.colValue;
    });
    this.service.updateUserPreferences(this.controller, gridColsKEY, options);
  }

  showExtraActions(event: any, cell: any) {
    const extraHeight = this.extraActions.length * 25;
    let posTop =
      cell.row.pos +
      cell.row.grid?.scrollPosition.y +
      this.grid.columnHeaders.height;
    if (cell.row.grid.clientSize.height - posTop > extraHeight) {
      /* opens from top */
      this.extraParams.elmTop = posTop + 13;
    } else {
      /* opens from bottom */
      this.extraParams.elmTop = posTop - extraHeight + 21 + 13;
      /* 21 pixels for height of actions icon **** 3 pixels for the padding of item */
    }
    if (this.isRTL) {
      /* rtl direction */
      this.extraParams.elmRight =
        cell.col.pos +
        this.grid.rowHeaders.columns.defaultSize +
        cell.col.grid.scrollPosition.x +
        13;
      /* added scroll position for when the grid action is scrolled into the grid */
      // Delete Next Line to enable RTL and LTR
      //this.extraParams.elmLeft = cell.col.pos + this.grid.rowHeaders.columns.defaultSize + cell.col.grid.scrollPosition.x + 13;
      //this.extraParams.elmLeft = 0;
    } else {
      /* ltr direction */
      this.extraParams.elmLeft =
        cell.col.pos +
        this.grid.rowHeaders.columns.defaultSize +
        cell.col.grid.scrollPosition.x +
        13;
      /* added scroll position for when the grid action is scrolled into the grid */
      // Delete Next Line to enable RTL and LTR
      //this.extraParams.elmRight = cell.col.pos + this.grid.rowHeaders.columns.defaultSize + cell.col.grid.scrollPosition.x + 13;
      //this.extraParams.elmRight = 0;
    }
    this.extraParams.hideExtra = false;
    this.extraParams.item = cell.item;
  }

  doDelete($event: any, id: any) {
    $event.preventDefault;
    this.delete.next(id);
    return false;
  }

  doDeleteForever($event: any, id: any) {
    $event.preventDefault;
    this.deleteForever.next(id);
    return false;
  }

  doUpdateIds(checked: boolean, id: any, index: number) {
    this.cvData.items[index].checked = checked;
    this.isAllChecked = this.cvData.items.every((item) => item.checked);
    this.updateIds.next({ id: id, checked: checked });
  }

  doToggleCheck(checked: boolean) {
    // Get checkbox column
    const checkboxCol = this.activeColumns.find(
      (col) => col?.type === 'checkbox'
    );

    if (checked) {
      const checkedIds = this.cvData.items.reduce((acc, item) => {
        if (
          checkboxCol &&
          checkboxCol.disableKey &&
          item[checkboxCol.disableKey]
        )
          item.checked = false;
        else {
          item.checked = true;
          acc.push(item.id); // Only add the checked item IDs
        }
        return acc;
      }, []);

      this.toggleCheck.next({ ids: checkedIds });
    } else {
      this.cvData.items.forEach((item) => (item.checked = false));
      this.toggleCheck.next({ ids: [] });
    }
  }

  doRestore($event: any, ids: any = []) {
    $event.preventDefault;
    this.restore.next(ids);
    return false;
  }

  hideExtra() {
    this.extraParams.hideExtra = true;
    this.extraParams.item = {};
  }

  checkMove(event: any) {
    let onextra = false;
    const eid = this.controller + '-extra-action';
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((item: any) => {
      if (item.id == eid) {
        onextra = true;
      }
    });
    if (!onextra) {
      this.hideExtra();
    }
  }

  pushExtraAction(prms: any) {
    this.applyAction.next(prms);
  }

  extraActionsForItem(code: any, item: any) {
    this.applyAction.next({ action: code, item: item });
  }

  executeAction(code: any) {
    this.applyAction.next({ action: code, item: this.extraParams.item });
    this.hideExtra();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  toggleVisibility(key: any, status: any, id: any = 'all') {
    let ids = [];
    if (id == 'all') {
      this.cvData.sourceCollection.map((item) => {
        item['visible'] = status;
        this.service.updateResourceField(item['id'], 'visible', status);
        if (status) {
          ids.push(item.id);
        }
        return item;
      });

      setTimeout(() => {
        this.service.visibleItems.next({
          type: 'all',
          checked: status,
          ids: ids,
        });
      }, 500);
    } else {
      ids = AfaqyHelper.cloneObject(
        this.authService.preferences(this.controller, key)
      );
      if (status) {
        ids.push(id);
      } else {
        AfaqyHelper.removeFromArray(ids, id);
      }
      this.cvData.sourceCollection.map((item) => {
        if (item.id == id) {
          item['visible'] = status;
          this.service.updateResourceField(item['id'], 'visible', status);
          this.service.updateMapVisibility(item['id'], 'visible', status);
        }
        return item;
      });
      setTimeout(() => {
        this.service.visibleItems.next({
          type: 'single',
          checked: status,
          item: this.cvData.sourceCollection.find((d) => d.id === id),
          ids: ids,
        });
      }, 500);
    }
    this.service.pushChanges({ action: 'visible' });
    this.authService.updatePreferences(this.controller, key, ids);
  }

  ngAfterViewInit(): void {
    if (this.grid && this.grid.isInitialized) {
      this.grid.rowHeaders.columns.defaultSize = 25;
    }
    this.updateGridResources();
  }

  /**
   * Get filtered items when filter grid applied and send it to filteredItems event.
   * @param filterObj Instance of FlexGridFilter Object.
   */
  onFilterApplied(filterObj: any): void {
    if (
      filterObj instanceof WjFlexGridFilter &&
      filterObj.grid.collectionView
    ) {
      const filteredItems: any[] = filterObj.grid.collectionView.items;
      this.filteredItems.next(filteredItems);
    }
  }

  /**
   * for creating wijmo collection view from items source data or updating existing reference with data
   * @param data : any[] displayed data in grid
   * @private accessed only from within this component
   */
  private _createItemsSource(data: any[]): wjcCore.CollectionView {
    let generatedArr = ['units'];
    generatedArr.forEach((element) => {
      data.forEach((d) => {
        if (d[element] && d[element].length)
          d[element + '_no'] = d[element].length;
      });
    });
    const view = new wjcCore.CollectionView(data, {
      getError: (item: any, prop: any) => {},
    });
    return view;
  }

  formatItem(flex: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) {
    e.cell.setAttribute('testid', flex.columns[e.col]['_hdr'] + '-cell');
  }
}
