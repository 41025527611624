<div *ngIf="message && message.message">
  <alert
    [type]="message.type"
    [dismissible]="message.dismissible"
    [dismissOnTimeout]="message.timeout"
    (onClosed)="onClosed($event)"
  >
    <span>{{ langKey + message.message | translate }}</span>
    <span
      [ngbPopover]="tooltipcontent"
      [placement]="popoverPlacement"
      class="ml-5"
      [closeDelay]="500"
      popoverClass="msg-container"
      *ngIf="messageList.length"
      triggers="mouseenter:mouseleave"
    >
      <i class="fa fa-exclamation-circle"></i>
    </span>
  </alert>

  <ng-template #tooltipcontent>
    <div *ngFor="let messages of messageList">
      <p [style.direction]="translate.currentLang == 'ar' ? 'rtl' : 'ltr'">
        <strong>{{ messages.msg.key }}</strong>
      </p>
      <p *ngFor="let value of messages.msg.value" style="white-space: nowrap">
        {{ 'validations.' + value | translate }}
      </p>
      <!--      <hr />-->
    </div>
  </ng-template>
</div>
