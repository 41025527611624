import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig, AppModel } from 'app/common';
import { AfaqyResponse } from 'app/core/classes';
import { SocketService } from 'app/core/services/socket.service';
import { UnitGroupService, UnitService } from 'app/modules/units/services';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { ServiceType, Unit } from '../../../../modules/units/models';
import { CopyUnit } from './copy-unit';
import { copyToExportOptions } from './export-options-units';
import { PusherSocketService } from 'app/core/services/pusher-socket.service';

@Component({
  selector: 'unit-settings-copy-component',
  templateUrl: './unit-settings-copy.component.html',
  styleUrls: ['./unit-settings-copy.component.scss'],
})
export class UnitSettingsCopyComponent implements OnInit {
  @ViewChild('stepperModel', { read: ViewContainerRef })
  stepperModel: ViewContainerRef;
  @Input() item: any;
  @Input() cid;
  form: FormGroup;
  panelOpenState = false;
  posting = false;
  searchKeys = [];
  // unitsList = [];
  searchValue = '';
  searchValueSubject: Subject<string> = new Subject<string>();
  searchKey = 'all';
  alive = true;
  unitItem: any;
  errorList: any;
  // filteredList;
  units: {
    [name: string]: CopyUnit;
  } = {};
  isModelShow = false;
  socketData: any;
  unitsColumns = 3;
  filteredUnits: CopyUnit[] = [];
  unitsRows: string[][];
  checkedUnitIds: string[];
  // checkedUnitsRows: string[][];
  searchText;
  totalErrorsNumber: number;
  // object: any;
  firstGroupValid;
  exportOptions = new copyToExportOptions();

  displayedColumns: string[] = ['unit_name', 'error', 'totalErrors'];
  customFieldsPermission: boolean;

  constructor(
    public translate: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public fb: FormBuilder,
    public service: UnitService,
    public groupService: UnitGroupService,
    private socketService: SocketService
  ) {
    this.searchKeys = this.service.getSearchKeys();
  }

  get listURL() {
    return '/units';
  }

  modalClose() {
    this.isModelShow = true;
    this.router.navigate([this.listURL], {
      skipLocationChange: AppConfig.skipLocationChange,
    });
  }

  checkIfTabChecked() {
    const group = this.form.controls['tabOne']['controls'];
    this.firstGroupValid = Object.keys(group).some((key: string) => {
      const value = group[key].value;
      if (Array.isArray(value)) {
        return value.length;
      } else {
        return group[key].value;
      }
    });
  }

  selectAllOptions(event: any, sectionName: string) {
    if (event.checked === true) {
      this.exportOptions[sectionName].forEach((option: any) => {
        this.form.controls.tabOne['controls'][option].setValue(true);
      });
    } else {
      this.exportOptions[sectionName].forEach((option: any) => {
        this.form.controls.tabOne['controls'][option].setValue(false);
      });
    }
  }

  // TODO refactor to better solution ' handle with formValues '
  selectSingleOption(event: any, option: string, sectionName: string) {
    if (event.checked === false) {
      this.form.controls.tabOne['controls'][sectionName].setValue(false);
      // TODO refactor to handle sensors more dynamically
      if (sectionName === 'sensors') {
        this.form.controls.tabOne['controls'].sensorsValues.setValue(
          this.form.controls.tabOne['controls'].sensorsValues.value.filter(
            (item: any) => option !== item
          )
        );
      }
      if (sectionName === 'custom_fields') {
        this.form.controls.tabOne['controls'].custom_fieldsValues.setValue(
          this.form.controls.tabOne[
            'controls'
          ].custom_fieldsValues.value.filter((item: any) => option !== item)
        );
      }
    } else {
      // TODO refactor to handle sensors more dynamically and add in one formControlName Object instead of sensor.id key
      if (sectionName === 'sensors') {
        this.form.controls.tabOne['controls'].sensorsValues.value
          ? this.form.controls.tabOne['controls'].sensorsValues.setValue([
              ...this.form.controls.tabOne['controls'].sensorsValues.value,
              option,
            ])
          : this.form.controls.tabOne['controls'].sensorsValues.setValue([
              option,
            ]);
        this.form.controls.tabOne['controls'][option].setValue(true);
      }
      if (sectionName === 'custom_fields') {
        this.form.controls.tabOne['controls'].custom_fieldsValues.value
          ? this.form.controls.tabOne['controls'].custom_fieldsValues.setValue([
              ...this.form.controls.tabOne['controls'].custom_fieldsValues
                .value,
              option,
            ])
          : this.form.controls.tabOne['controls'].custom_fieldsValues.setValue([
              option,
            ]);
        this.form.controls.tabOne['controls'][option].setValue(true);
      }
    }
  }

  selectAllSensorsOptions(event: any) {
    if (event.checked === true) {
      this.item.sensors.map((sensor: any) => {
        this.form.controls.tabOne['controls'].sensorsValues.value
          ? this.form.controls.tabOne['controls'].sensorsValues.setValue([
              ...this.form.controls.tabOne['controls'].sensorsValues.value,
              sensor.id,
            ])
          : this.form.controls.tabOne['controls'].sensorsValues.setValue([
              sensor.id,
            ]);
        this.form.controls.tabOne['controls'][sensor.id].setValue(true);
      });
    } else {
      this.item.sensors.map((sensor: any) => {
        this.form.controls.tabOne['controls'].sensorsValues.setValue([]);
        this.form.controls.tabOne['controls'][sensor.id].setValue(false);
      });
    }
  }
  selectAllCustomFieldsOptions(event: any) {
    if (event.checked === true) {
      this.item.custom_fields.map((customField: any) => {
        this.form.controls.tabOne['controls'].custom_fieldsValues.value
          ? this.form.controls.tabOne['controls'].custom_fieldsValues.setValue([
              ...this.form.controls.tabOne['controls'].custom_fieldsValues
                .value,
              customField.id,
            ])
          : this.form.controls.tabOne['controls'].custom_fieldsValues.setValue([
              customField.id,
            ]);
        this.form.controls.tabOne['controls'][customField.id].setValue(true);
      });
    } else {
      this.item.custom_fields.map((customField: any) => {
        this.form.controls.tabOne['controls'].custom_fieldsValues.setValue([]);
        this.form.controls.tabOne['controls'][customField.id].setValue(false);
      });
    }
  }

  updateSearchTextInput(value) {
    this.searchValue = value;
    this.searchValueSubject.next(value);
  }

  /**
   * subscription for changing search text
   */
  filterUnitsSubscription() {
    this.searchValueSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.filterUnits(this.service.resourcesList);
      });
  }

  /**
   * trigger filter on changing key
   * @param value
   */
  updateSearchKey(value) {
    this.searchKey = value;
    this.filterUnits(this.service.resourcesList);
  }

  /*
        filterUnitsList() {
            Object.keys(this.units).map((id) => {
                this.units[id].visible = this.service.applYFilter(this.units[id], this.searchKey, this.searchValue);
            });
        }
    */

  buildUnitsCopyInfoForm() {
    this.form = this.fb.group({
      tabOne: this.fb.group({
        main: [],
        device: [],
        device_type: [],
        device_password: [],
        profile: [],
        vehicle_type: [],
        measurement_type: [],
        max_capacity: [],
        model: [],
        fuel_type: [],
        year: [],
        seats: [],
        auto_tailer: [],
        auto_driver: [],
        fc: [false],
        accuracy: [false],
        shipment: [false],
        sensors: [false],
        sensorsValues: [[]],
        commands: [false],
        services: [false],
        groups: [false],
        driver_behavior: [false],
        custom_fields: [false],
        custom_fieldsValues: [[]],
        users_list: [false],
      }),
      tabTwo: this.fb.group({
        action_type: ['', Validators.required],
      }),
      tabThree: this.fb.group({
        source_id: ['', Validators.required],
        destination_ids: ['', Validators.required],
      }),
    });
  }

  onSubmit() {
    this.posting = true;
    this.refactorObjectBeforeSubmit(this.form.value);
    this.create(this.form.value)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response: AfaqyResponse) => {
          this.posting = false;
          this.socketService.unitCopyInfoSubmitted.next(true);
          this.socketService.showCopyInfoSpinner.next(true);
        },
        (error) => {
          //Logger.error(error);
          // this.afterFail(error);
        }
      );
  }

  create(obj: AppModel, user = true) {
    const params: any = { ...obj };
    return this.service.doCreate(this.service.getFunctionURL('copy'), params);
  }

  closeModal() {
    this.isModelShow = true;
    this.socketService.unitCopyInfoSubmitted.next(false);
  }

  //   TODO refactor
  refactorObjectBeforeSubmit(formValue) {
    Object.keys(this.form.controls['tabOne']['controls']).forEach(
      (control: string) => {
        formValue[control] = formValue.tabOne[control];
      }
    );
    delete formValue.tabOne;
    Object.keys(this.form.controls['tabTwo']['controls']).forEach(
      (control: string) => {
        formValue[control] = formValue.tabTwo[control];
      }
    );
    delete formValue.tabTwo;
    Object.keys(this.form.controls['tabThree']['controls']).forEach(
      (control: string) => {
        formValue[control] = formValue.tabThree[control];
      }
    );
    delete formValue.tabThree;
    if (formValue.main) {
      formValue.main = ['device', 'device_password', 'device_type'];
    } else {
      formValue.main = [];
      if (formValue.device) {
        formValue.main.push('device');
      }
      if (formValue.device_password) {
        formValue.main.push('device_password');
      }
      if (formValue.device_type) {
        formValue.main.push('device_type');
      }
    }
    this.item.sensors.map((sensor: any) => {
      delete formValue[sensor.id];
    });
    if (formValue.sensorsValues.length) {
      formValue.sensors = true;
    }
    this.item.custom_fields.map((customField: any) => {
      delete formValue[customField.id];
    });
    if (formValue.custom_fieldsValues.length) {
      formValue.custom_fields = true;
    }
    if (formValue.profile) {
      formValue.profile = [
        'vehicle_type',
        'measurement_type',
        'max_capacity',
        'model',
        'fuel_type',
        'year',
        'seats',
        'auto_tailer',
        'auto_driver',
      ];
    } else {
      formValue.profile = [];
      if (formValue.vehicle_type) {
        formValue.profile.push('vehicle_type');
      }
      if (formValue.measurement_type) {
        formValue.profile.push('measurement_type');
      }
      if (formValue.max_capacity) {
        formValue.profile.push('max_capacity');
      }
      if (formValue.model) {
        formValue.profile.push('model');
      }
      if (formValue.fuel_type) {
        formValue.profile.push('fuel_type');
      }
      if (formValue.year) {
        formValue.profile.push('year');
      }
      if (formValue.seats) {
        formValue.profile.push('seats');
      }
      if (formValue.auto_tailer) {
        formValue.profile.push('auto_tailer');
      }
      if (formValue.auto_driver) {
        formValue.profile.push('auto_driver');
      }
    }
    delete formValue.vehicle_type;
    delete formValue.measurement_type, delete formValue.max_capacity;
    delete formValue.model, delete formValue.fuel_type;
    delete formValue.year;
    delete formValue.seats;
    delete formValue.auto_tailer;
    delete formValue.auto_driver;
    delete formValue.device;
    delete formValue.device_password;
    delete formValue.device_type;
    return formValue;
  }

  setActionTypeValue(value) {
    this.form.controls.tabTwo['controls'].action_type.setValue(value);
  }

  ngOnInit() {
    this.buildUnitsCopyInfoForm();
    if (this.item) {
      this.item?.sensors.map((sensor: any) => {
        const groupTabOne = this.form.get('tabOne') as FormGroup;
        groupTabOne.addControl(sensor.id, new FormControl(false));
      });
      this.item?.custom_fields.map((customField: any) => {
        const groupTabOne = this.form.get('tabOne') as FormGroup;
        groupTabOne.addControl(customField.id, new FormControl(false));
      });
      this.form.controls.tabThree['controls'].source_id.setValue(this.item.id);
    }
    this.form.valueChanges.subscribe((value: any) => {
      this.checkIfTabChecked();
    });
    this.service.finishedLoading
      .pipe(takeWhile(() => this.alive))
      .subscribe((finishedLoading) => {
        if (finishedLoading) {
          this.service.resourcesList.map((unit: Unit) => {
            const unitObj = new CopyUnit();
            unitObj.copyInto(unit);
            this.units[unit.id] = unitObj;
            // this.units[unit.id].checked = false;
            // this.units[unit.id].visible = true;
          });
          this.filterUnits(this.service.resourcesList);
        }
      });

    this.service.subscribeToPusherSocketNotifications();
    if (
      this.service?.copyInfoData?.operation_status === 'completed_with_failures'
    ) {
      this.getErrorsList();
    }
    this.filterUnitsSubscription();
    this.customFieldsPermission = this.checkCustomFieldsPermission();
  }

  checkCustomFieldsPermission() {
    return this.service.authService.checkPermissions('custom_fields-lists');
  }

  /**
   * triggered on changing selected units
   */
  changedSelectedUnits() {
    this.checkedUnitIds = Object.values(this.units)
      .filter((unit: CopyUnit) => {
        return unit?.checked;
      })
      .map((unit: CopyUnit) => {
        return unit.id;
      });
    // this.form.controls.destination_ids.setValue(this.checkedUnitIds)
    this.form.controls.tabThree['controls'].destination_ids.setValue(
      this.checkedUnitIds
    );
    /**
     * used if needed to have seperate container for selected items
     */
    // this.checkedUnitsRows = this.splitArrayIntoGroups(this.checkedUnitIds, this.unitsColumns);

    this.filterUnits(this.service.resourcesList);
  }

  /**
   * filter units based on search key and search value and sort based on checked first
   * @param units
   */
  filterUnits(units: CopyUnit[]) {
    this.filteredUnits = units
      .filter((unit, index) => {
        return (
          unit?.id !== this.item?.id &&
          unit.permission !== 'view_only' &&
          this.service.applYFilter(unit, this.searchKey, this.searchValue)
        );
      })
      .sort((a, b) => this.sortChecked(a, b));
    this.unitsRows = this.generateUnitsRows(this.filteredUnits);
  }

  /**
   * sort units by checked first
   * @param a CopyUnit unit to compare
   * @param b CopyUnit unit to compare
   */
  sortChecked(a: CopyUnit, b: CopyUnit): number {
    const checkedA = this.units[a.id].checked;
    const checkedB = this.units[b.id].checked;
    return Number(checkedB) - Number(checkedA);
  }

  /**
   * generate units rows based on global units colums set
   * @param units
   */
  generateUnitsRows(units: CopyUnit[]) {
    const unitsIds: string[] = units
      .filter((unit) => unit?.id !== this.item?.id)
      .map((unit) => {
        return unit.id;
      });

    return this.splitArrayIntoGroups(unitsIds, this.unitsColumns);
  }

  /**
   * splits array of ids into array of array of ids based on group size
   * @param array
   * @param groupSize
   */
  splitArrayIntoGroups(array, groupSize) {
    const result = [];
    for (let i = 0; i < array.length; i += groupSize) {
      result.push(array.slice(i, i + groupSize));
    }
    return result;
  }

  clearSelected() {
    Object.keys(this.units).forEach((id) => {
      this.units[id].checked = false;
    });
    this.changedSelectedUnits();
  }

  /**
   * select all units
   */
  selectAllUnits() {
    Object.keys(this.units).forEach((id) => {
      this.units[id].checked = true;
    });
    this.changedSelectedUnits();
  }

  /**
   * select all filtered units
   */
  selectAllFilteredUnits() {
    this.filteredUnits.forEach((unit) => {
      this.units[unit.id].checked = true;
    });
    this.changedSelectedUnits();
  }

  getErrorsList() {
    this.service
      .doGet(`units/copy/${this.service.processId}/failure`, {})
      .pipe(takeWhile(() => this.alive))
      .subscribe((errorsList: any) => {
        this.totalErrorsNumber = errorsList.extra.map((total: any) => {
          return total.fails;
        });
        this.errorList = errorsList.data;
      });
  }
}
